import {
  Box,
  Container,
  Typography,
  alpha,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  useTheme,
} from '@mui/material';
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import useAmazonAIBestSellerDiscoverer, {
  type AmazonAIBestSellerDiscovererType,
} from '../../../utils/hooks/useAmazonAIBestSellerDiscoverer';
import { useReactiveVar } from '@apollo/client';
import { AIBestSellerProductCategoriesState } from '../../../stateManagement/AIBestSellerProductCategoriesState';
import { visuallyHidden } from '@mui/utils';
import { Add } from '@mui/icons-material';
import { capitalize, get } from 'lodash';
import COLORS from '../../../utils/CONSTS/COLORS';
import ProductSuggestionReasonModal from '../../../components/modals/ProductSuggestionReasonModal';
import StartCompareListModal from '../../../components/modals/StartCompareListModal';
import CompareListComponent from '../../../components/CompareListComponent';
import useCompareList from '../../../utils/hooks/useCompareList';
import useEbayDraftListing from '../../../utils/hooks/useEbayDraftListing';

import { EbayListingsState } from '../../../stateManagement/EbayListingsState';
import { useNavigate } from 'react-router-dom';
import { type IEbayListingGroup } from '../../../utils/TYPES';
import moment, { type MomentInput } from 'moment';

type Order = 'asc' | 'desc';
interface HeadCell {
  disablePadding: boolean;
  id: keyof IEbayListingGroup['itemGroup'] | keyof IEbayListingGroup;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'imageUrls',
    numeric: false,
    disablePadding: false,
    label: 'Image',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'totalVariants',
    numeric: true,
    disablePadding: false,
    label: 'Variations',
  },

  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'updatedAt',
    numeric: true,
    disablePadding: false,
    label: 'last Updated',
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: Order,
  orderBy: keyof IEbayListingGroup['itemGroup'] | keyof IEbayListingGroup,
): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: keyof IEbayListingGroup['itemGroup'] | keyof IEbayListingGroup;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler =
    (
      property: keyof IEbayListingGroup['itemGroup'] | keyof IEbayListingGroup,
    ) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EbayListingLandingPage = () => {
  const { syncEbayListings } = useEbayDraftListing();

  useEffect(() => {
    syncEbayListings();
  }, []);

  const ebayListings = useReactiveVar(EbayListingsState);

  const Navigate = useNavigate();
  const { palette } = useTheme();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<
    // eslint-disable-next-line prettier/prettier
      keyof IEbayListingGroup['itemGroup'] | keyof IEbayListingGroup
  >('updatedAt');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IEbayListingGroup['itemGroup'] | keyof IEbayListingGroup,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ebayListings.length}
            />
            <TableBody>
              {ebayListings.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={false}
                    onClick={() => {
                      Navigate(`/ebay-listings/${row.id}`);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                      sx={{ cursor: 'pointer' }}
                    >
                      <img
                        src={row.itemGroup?.imageUrls?.[0]}
                        alt="product"
                        loading="lazy"
                        style={{
                          width: 200,
                          height: 'auto',
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">{row?.itemGroup?.title}</TableCell>
                    <TableCell align="right">{row.totalVariants}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right">
                      {moment(row?.updatedAt.toDate() as MomentInput).format(
                        'MM/DD/YYYY hh:mm a',
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ebayListings?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Container>
  );
};

// const EbayListingLandingPage = () => {
//   const { syncEbayListings } = useEbayDraftListing();

//   useEffect(() => {
//     syncEbayListings();
//   }, []);

//   const ebayListings = useReactiveVar(EbayListingsState);

//   const Navigate = useNavigate();

//   return (
//     <Container>
//       <Box>
//         <Typography variant="h4">YOUR LISTINGS</Typography>
//       </Box>
//       {!!ebayListings?.length &&
//         ebayListings?.map(({ id, itemGroup, status }) => (
//           <Box
//             key={id}
//             sx={{
//               border: '1px solid black',
//               p: 2,
//               my: 2,
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               cursor: 'pointer',
//             }}
//             onClick={() => {
//               Navigate(`/ebay-listings/${id}`);
//             }}
//           >
//             <img
//               src={itemGroup?.imageUrls?.[0]}
//               alt={itemGroup?.title}
//               style={{ width: '100px', height: '100px' }}
//             />
//             <Typography>{itemGroup?.title}</Typography>
//             <Typography
//               variant="h6"
//               color={status === 'PUBLISHED' ? 'success' : 'primary'}
//             >
//               {status}{' '}
//             </Typography>
//             {/* <Typography variant="h6">
//               {offer?.pricingSummary?.price?.value}{' '}
//               {offer?.pricingSummary?.price?.currency}
//             </Typography> */}
//           </Box>
//         ))}
//     </Container>
//   );
// };

export default EbayListingLandingPage;
