/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Grid2, Typography, useTheme } from '@mui/material';
import React from 'react';
import { type AmazonProductType } from '../utils/TYPES';
import Box from './Box';
import createAffiliateLink from '../utils/helpers/createAffiliateLink';
import CopyText from './CopyText';
import { truncate } from 'lodash';
import useIsInCompareList from '../utils/hooks/useIsInCompareList';
import { Add, DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { type AmazonAIBestSellerDiscovererType } from '../utils/hooks/useAmazonAIBestSellerDiscoverer';
import useAmazon from '../utils/hooks/useAmazon';
import { ActiveMarketPlace } from '../stateManagement/ActiveMarketPlace';

interface ProductCardProps {
  item: AmazonAIBestSellerDiscovererType;
  addRemoveProductsToCompareList?: (products: AmazonProductType[]) => void;
  hasActionButton?: boolean;
  compareListLoading?: boolean;
  createDraftFromAmazonTaskManager?: (productId: string) => Promise<unknown>;
  hasListButton?: boolean;
}

const ProductCard = ({
  item,
  addRemoveProductsToCompareList,
  hasActionButton,
  compareListLoading,
  createDraftFromAmazonTaskManager,
  hasListButton,
}: ProductCardProps) => {
  const { palette } = useTheme();
  const isInCompareList = useIsInCompareList({ asin: item?.asin });
  const { getProductDetail } = useAmazon();
  const handleClick = async () => {
    await getProductDetail(item?.asin);
  };

  return (
    <Grid2
      key={item?.asin}
      size={{ xs: 12, sm: 6, md: 3, lg: 2 }}
      sx={{
        backgroundColor: palette?.surface?.dark,
        borderRadius: 2,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        paddingBottom: 2,
      }}
      onClick={handleClick}
    >
      <Box center flex column spaceBetween height={'100%'}>
        <Box
          overflow="hidden"
          center
          flex
          backgroundColor={'white'}
          pr={10}
          pl={10}
          aspectRatio={1}
        >
          <a
            href={createAffiliateLink(item?.asin)}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={item.product_photo}
              alt="product"
              loading="lazy"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </a>
        </Box>
        <Box
          pl={15}
          pr={15}
          pt={15}
          flex
          style={{
            flexDirection: 'column',
            justifySelf: 'flex-start',
            alignSelf: 'flex-start',
          }}
        >
          <CopyText
            text={item.product_title}
            concatLength={70}
            color={palette?.primary?.main}
          />
          {item?.score && (
            <Typography mt={2} sx={{ opacity: 0.7 }}>
              {item?.score}
            </Typography>
          )}
          <Typography mt={2} sx={{ opacity: 0.7 }}>
            {truncate(item?.sales_volume, { length: 50, separator: ' ' })}
          </Typography>
          {/* <Typography mt={2} sx={{ opacity: 0.7 }}>
            {item?.itemSoldLastMonth}
          </Typography> */}

          {/* <Typography>{item?.delivery}</Typography> */}
        </Box>

        <Box>
          <Box>
            <Typography variant="h6" mb={1}>
              {item?.product_price} {item?.currency}
            </Typography>
          </Box>
          <Box>
            <Typography mb={1}>{item?.category}</Typography>
          </Box>
          {/* <Box>
            <Typography mb={1}>{item?.reason}</Typography>
          </Box> */}
          {hasListButton && createDraftFromAmazonTaskManager && (
            <LoadingButton
              variant="contained"
              onClick={() => {
                createDraftFromAmazonTaskManager?.(item?.asin);
              }}
              loading={compareListLoading}
              color={isInCompareList ? 'error' : 'primary'}
            >
              list on ebay
            </LoadingButton>
          )}
          {hasActionButton && (
            <LoadingButton
              variant="contained"
              onClick={() => {
                addRemoveProductsToCompareList &&
                  addRemoveProductsToCompareList([item]);
              }}
              loading={compareListLoading}
              color={isInCompareList ? 'error' : 'primary'}
            >
              {isInCompareList ? <DeleteOutline /> : <Add />}
              {isInCompareList ? 'Remove' : 'Add to list'}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Grid2>
  );
};

export default ProductCard;
