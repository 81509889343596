/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  FormLabel,
  Grid,
  Grid2,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CENTER } from '../../../utils/CONSTS/PRESTYLES';
import useEbayInventoryItem from '../../../utils/hooks/useEbayInventoryItem';
import useEbayDraftListing from '../../../utils/hooks/useEbayDraftListing';
import { cloneDeep, get, isEqual, set, uniq } from 'lodash';
import Loading from '../../../components/Loading';
import {
  type IEbayGroupItemDraftListing,
  type IEbayDraftListing,
  type IEbayListingGroup,
} from '../../../utils/TYPES';
import { GridDeleteIcon } from '@mui/x-data-grid';
import {
  Add,
  AddPhotoAlternate,
  LinkOff,
  LinkOutlined,
  OpenInNew,
  Warning,
} from '@mui/icons-material';
import HTMLEditor from '../../../components/HtmlEditor';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { LoadingButton, type TabPanelProps } from '@mui/lab';
import { calculateEbayProfitOrSellingPrice } from '../../../utils/helpers/ebayProfitCalculator';
import { from, useReactiveVar } from '@apollo/client';
import { EbayInventoryLocationsState } from '../../../stateManagement/EbayInventoryLocationsState';
import EbayLogo from '../../../components/EbayLogo';
import AmazonLogo from '../../../components/AmazonLogo';
import { ModalStateManager } from '../../../stateManagement/ModalStateManager';
import COLORS from '../../../utils/CONSTS/COLORS';
import { EbayPolicyState } from '../../../stateManagement/EbayPolicyState';
import TabPanel from '../../../components/TabPanel';
import EbayVariations from '../../../components/EbayVariations';
import EditableAspects from '../../../components/EditableApects';
import AliExpressLogo from '../../../components/AliExpressLogo';
import { isSandbox } from '../../../env';

const EbayListingDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { palette, direction } = useTheme();

  const styles: Record<string, React.CSSProperties> = {
    itemContainer: {
      borderRadius: 10,
      backgroundColor: palette?.surface?.dark,
      padding: 15,
      marginTop: 20,
    },
  };
  const {
    loading,
    ebayListing,
    getEbayDraftListing,
    saveEbayDraftListing,
    ebayAIdescriptionEditor,
    ebayAItitleEditor,
    marketPlaceId,
    ebayDraftListingVariations,
    listedMarketPlace,
    deleteEbayListing,
  } = useEbayDraftListing();

  const {
    listInventoryItemOnEbay,
    getReturnPolicy,
    getFulfillmentPolicy,
    getPaymentPolicy,
    getInventoryLocations,
  } = useEbayInventoryItem();

  useEffect(() => {
    // update inventory Location in database
    getInventoryLocations();
  }, []);

  console.log(
    'marketPlaceIdmarketPlaceIdmarketPlaceIdmarketPlaceId',
    marketPlaceId,
  );

  useEffect(() => {
    if (marketPlaceId) {
      getFulfillmentPolicy(marketPlaceId);
      getReturnPolicy(marketPlaceId);
      getPaymentPolicy(marketPlaceId);
    }
  }, [marketPlaceId]);

  const ebayInventoryLocations = useReactiveVar(EbayInventoryLocationsState);

  const [formState, setFormState] = useState<IEbayListingGroup | null>(null);

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    if (!ebayListing) return;
    setFormState(cloneDeep(ebayListing));
  }, [ebayListing]);

  useEffect(() => {
    getEbayDraftListing(id as string);
  }, []);

  const handleChange = (e: any, path: string[]) => {
    const _value = e.target.value;

    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: any) => {
      const updatedState = { ...prevState };

      let current: any = updatedState;
      for (let i = 0; i < path.length - 1; i++) {
        current = current?.[path?.[i]] || null;
      }
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(_value) : _value; // Always update the value correctly

      return updatedState; // Return the updated state
    });
  };

  const AEProductId =
    listedMarketPlace === 'Ali Express' &&
    (formState?.originalProduct as any)?.ae_item_base_info_dto
      ? (formState?.originalProduct as any).ae_item_base_info_dto.product_id
      : undefined;

  // const handleAddImage = (imageUrl: any) => {
  //   setFormState((prevState: any) => ({
  //     ...prevState,
  //     product: {
  //       ...prevState.product,
  //       product: {
  //         ...prevState.product.product,
  //         imageUrls: [...prevState.product.product.imageUrls, imageUrl],
  //       },
  //     },
  //   }));
  // };

  const doesNeedToSave = !isEqual(ebayListing, formState);

  const handleDeleteImage = (index: number) => {
    setFormState((prevState: any) => ({
      ...prevState,
      product: {
        ...prevState,
        imageUrls: prevState.imageUrls.filter(
          (_: any, i: number) => i !== index,
        ),
      },
    }));
  };

  const [errorTexts, setError] = useState<string[] | []>([]);

  const isReadyToPublish = useMemo(() => {
    if (!formState) {
      setError((prev) => [...prev, 'From state doesn exist']);
      return;
    }
    setError([]);
    if (!formState?.itemGroup?.title) {
      setError((prev) => [...prev, 'Title is required']);
    }
    if (!formState?.itemGroup?.description)
      setError((prev) => [...prev, 'Description is required']);
    if (formState?.itemGroup?.imageUrls?.length === 0)
      setError((prev) => [...prev, 'At least one image is required']);
    if (
      Object.keys(formState?.itemGroup?.aspects).length <
      formState?.requiredAspects?.length
    )
      setError((prev) => [...prev, 'All required aspects are required']);
    if (formState?.itemGroup?.title?.length > 80)
      setError((prev) => [...prev, 'Title must be less than 80 characters']);
    if (formState?.itemGroup?.description?.length > 4000)
      setError((prev) => [
        ...prev,
        'Description must be less than 4000 characters',
      ]);

    return (
      formState?.itemGroup?.title &&
      formState?.itemGroup?.description &&
      formState?.itemGroup?.imageUrls?.length > 0 &&
      Object.values(formState?.itemGroup?.aspects).every(
        (value) => (value as string[]).length > 0,
      ) &&
      formState?.itemGroup?.title?.length <= 80 &&
      formState?.itemGroup?.description?.length <= 4000
    );
  }, [formState, formState?.requiredAspects]);

  const isMisMatchedLocation = useMemo(() => {
    return (
      // marketPlaceId?.split('_')[1] !==
      // ebayInventoryLocations?.filter(
      //   ({ merchantLocationKey: _merchantLocationKey }) =>
      //     _merchantLocationKey === formState?.itemGroup?.offer?.merchantLocationKey,
      // )[0]?.location?.address?.country
      false
    );
  }, [marketPlaceId, ebayInventoryLocations]);

  const onNoAspectInfoClick = (aspectName: string) => {
    if (aspectName.toLowerCase() === 'brand') {
      handleChange({ target: { value: ['Unbranded'] } }, [
        'itemGroup',
        'aspects',
        aspectName,
      ]);
    } else {
      handleChange({ target: { value: ['Does not apply'] } }, [
        'itemGroup',
        'aspects',
        aspectName,
      ]);
    }
  };

  if (loading || !formState)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <Loading />{' '}
      </Box>
    );

  const { title, description, imageUrls } = formState?.itemGroup || {};
  // const { length, width, height, unit } =
  //   formState.product.packageWeightAndSize.dimensions;
  // const { value: weightValue, unit: weightUnit } =
  //   formState.product.packageWeightAndSize.weight;
  // const { value: priceValue, currency } =
  //   formState?.itemGroup?.offer?.pricingSummary?.price;

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 5,
          mb: 5,
        }}
      >
        <Box>
          <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
            <img
              src={formState?.itemGroup?.imageUrls[0]}
              alt="product"
              style={{
                width: 100,
                height: 'auto',
                borderRadius: 5,
                marginRight: 10,
              }}
            />
            <Box>
              <Typography fontSize={18} fontWeight={'bold'} ml={1}>
                {formState?.itemGroup?.title}
              </Typography>
              <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                <Button
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: palette?.primary?.main,
                    mr: 2,
                  }}
                  onClick={() => {
                    !AEProductId
                      ? window.open(
                          formState?.originalProduct?.product_url,
                          '_blank',
                        )
                      : window.open(
                          `https://www.aliexpress.com/item/${AEProductId}.html`,
                          '_blank',
                        );
                  }}
                  startIcon={<OpenInNew />}
                >
                  Source Product
                  <Box ml={1}>
                    {AEProductId ? (
                      <AliExpressLogo size={70} />
                    ) : (
                      <AmazonLogo size={70} />
                    )}
                  </Box>
                </Button>
                <Typography mr={2} sx={{ opacity: 0.6 }}>
                  |
                </Typography>
                {formState?.status === 'PUBLISHED' && formState?.listingId && (
                  <Button
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: palette?.primary?.main,
                    }}
                    startIcon={<OpenInNew />}
                    onClick={() => {
                      window.open(
                        `https://${isSandbox ? 'sandbox.ebay.com' : 'www.ebay.com'}/itm/${formState?.listingId}`,
                        '_blank',
                      );
                    }}
                  >
                    Your Listing
                    <Box ml={1}>
                      <EbayLogo size={70} />
                    </Box>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={!doesNeedToSave}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              saveEbayDraftListing(formState, id as string);
            }}
          >
            Save
          </Button>
          <Button
            disabled={doesNeedToSave || !isReadyToPublish}
            variant="contained"
            color="success"
            fullWidth
            onClick={() => {
              isReadyToPublish && listInventoryItemOnEbay(id as string);
            }}
          >
            {formState?.status === 'PUBLISHED' ? 'Update' : 'Publish'}
          </Button>
          <Button
            onClick={() => {
              ModalStateManager({
                type: 'WARNING',
                title: 'Warning',
                subtitle:
                  formState?.status === 'PUBLISHED'
                    ? 'This product is already published to Ebay do you want to also unpublish this from ebay or just delete it from Drop Compare'
                    : 'Are you sure you want to delete this listing?',
                onAction: async () => {
                  await deleteEbayListing(id as string);
                },
                onActionText: 'Delete',
                onActionColor: 'error',
              });
            }}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </Box>
      {!!errorTexts?.length && (
        <Typography variant="h6">
          Before you can publish this listing you need to fix the following
        </Typography>
      )}
      {!!formState?.errors?.length && (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Warning color="error" />
            <Typography ml={1} sx={{ opacity: 0.6 }}>
              There are some errors in the form please fix them before you can
              publish
            </Typography>
          </Box>
          <List sx={{ width: '50%' }}>
            {formState?.errors?.map((error) => (
              <ListItem key={error?.errorId}>
                <Typography color="error">{error?.message}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <List>
        {uniq(errorTexts)?.map((error) => (
          <ListItem key={error}>
            <Warning color="error" />{' '}
            <Typography color="error">{error}</Typography>
          </ListItem>
        ))}
      </List>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{ backgroundColor: palette?.surface?.dark, borderRadius: 2 }}
      >
        <Tab label="Product" {...a11yProps(0)} />
        <Tab label="Variations" {...a11yProps(1)} />
        <Tab label="Images" {...a11yProps(2)} />
        <Tab label="Aspects (item specifics)" {...a11yProps(3)} />
      </Tabs>

      {/* Title & Description */}
      <TabPanel value={String(tabValue)} index={0} dir={direction}>
        <Box style={styles?.itemContainer}>
          <Typography variant="h6" gutterBottom mb={2}>
            REQUIRED ASPECTS
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: 'flex-end', display: 'flex' }}
          >
            {!!formState?.requiredAspects?.length &&
              formState?.requiredAspects?.map((aspect) => (
                <Grid item xs={6} key={aspect?.localizedAspectName}>
                  {(!formState?.itemGroup?.aspects?.[
                    aspect?.localizedAspectName
                  ]?.length ||
                    formState?.itemGroup?.aspects?.[
                      aspect?.localizedAspectName
                    ]?.[0] === '') && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography color="error" mb={1}>
                        Please insert the {aspect?.localizedAspectName} this
                        filed is required
                        <Typography
                          color="success"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            onNoAspectInfoClick(aspect?.localizedAspectName);
                          }}
                        >
                          if you don`t have this information click here
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label={aspect?.localizedAspectName}
                    variant="outlined"
                    required
                    type="text"
                    fullWidth
                    value={
                      formState?.itemGroup?.aspects?.[
                        aspect?.localizedAspectName
                      ]
                    }
                    onChange={(e) => {
                      handleChange({ target: { value: [e.target?.value] } }, [
                        'itemGroup',
                        'aspects',
                        aspect?.localizedAspectName,
                      ]);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box style={styles?.itemContainer}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" mr={1}>
                TITLE
              </Typography>
              <Typography color={title?.length > 80 ? 'error' : 'text'}>
                ({title?.length} / 80)
              </Typography>
            </Box>
            <LoadingButton
              startIcon={
                <AutoAwesomeIcon
                  fontSize="large"
                  sx={{ color: COLORS?.SECONDARY }}
                />
              }
              onClick={async () => {
                const edited = await ebayAItitleEditor(title + description);
                handleChange({ target: { value: edited } }, [
                  'itemGroup',
                  'title',
                ]);
              }}
            >
              AI Generated Title
            </LoadingButton>
          </Box>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            multiline
            color={title?.length > 80 ? 'error' : 'primary'}
            sx={{
              borderColor: title?.length > 80 ? 'red' : 'primary',
              backgroundColor:
                title?.length > 80
                  ? 'rgba(255, 0, 0, 0.1)'
                  : palette?.background?.default,
            }}
            rows={title?.length > 100 ? 2 : 1}
            inputProps={{ maxLength: 80 }}
            value={title}
            onChange={(e) => {
              handleChange(e, ['itemGroup', 'title']);
            }}
            margin="normal"
          />
          <Box>
            <FormLabel>Category</FormLabel>
            {formState?.categoryId && (
              <Select
                variant="outlined"
                fullWidth
                value={formState?.categoryId as string}
                onChange={(e: SelectChangeEvent) => {
                  handleChange(e, ['categoryId']);
                }}
              >
                {formState?.categorySuggestions?.length &&
                  formState?.categorySuggestions?.map((categorySuggestion) => (
                    <MenuItem
                      key={categorySuggestion?.category?.categoryId}
                      value={categorySuggestion?.category?.categoryId}
                    >
                      {categorySuggestion?.categoryTreeNodeAncestors
                        .reverse()
                        .map((item) => item?.categoryName)
                        .join(' > ')}
                      {' > '}
                      {categorySuggestion?.category?.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </Box>

          {/* Description */}
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" mr={1}>
                DESCRIPTION
              </Typography>
              <Typography color={description?.length > 4000 ? 'error' : 'text'}>
                ({description?.length} / 4000)
              </Typography>
            </Box>

            <LoadingButton
              startIcon={
                <AutoAwesomeIcon
                  fontSize="large"
                  sx={{ color: COLORS?.SECONDARY }}
                />
              }
              onClick={async () => {
                const edited = await ebayAIdescriptionEditor(description);
                handleChange({ target: { value: edited } }, [
                  'itemGroup',
                  'description',
                ]);
              }}
            >
              AI Generated Description
            </LoadingButton>
          </Box>
          <HTMLEditor
            value={description}
            onChange={(value) => {
              handleChange({ target: { value } }, ['itemGroup', 'description']);
            }}
          />
        </Box>
      </TabPanel>
      {/* Variations */}
      <EbayVariations
        tabValue={tabValue}
        ebayDraftListingVariations={ebayDraftListingVariations}
        itemContainerStyles={styles?.itemContainer}
        id={id}
        marketPlaceId={marketPlaceId}
      />
      {/* Images */}
      <TabPanel value={String(tabValue)} index={2} dir={direction}>
        <Box style={styles?.itemContainer}>
          {/* Image Management */}
          <Typography variant="h6">IMAGES</Typography>

          <Box mt={2}>
            <ImageList cols={4}>
              {!!imageUrls?.length &&
                imageUrls.map((url, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={url}
                      alt={`Product Image ${index + 1}`}
                      loading="lazy"
                      style={{ height: 200, width: 'auto' }}
                    />
                    <ImageListItemBar
                      actionIcon={
                        <IconButton
                          onClick={() => {
                            handleDeleteImage(index as number);
                          }}
                          color="error"
                        >
                          <GridDeleteIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
            </ImageList>
            {/* <Box display="flex" alignItems="center" marginTop={2}>
            <TextField
              sx={{ backgroundColor: palette?.background?.default }}
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files.length > 0) {
                  handleAddImage(e.target.files[0]);
                }
              }}
            />
            <IconButton color="primary">
              <AddPhotoAlternate />
            </IconButton>
          </Box> */}
          </Box>
        </Box>
      </TabPanel>
      {/* Shipping and Policies */}
      <TabPanel value={String(tabValue)} index={3} dir={direction}>
        <EditableAspects
          aspects={formState?.itemGroup?.aspects}
          setFormState={setFormState}
        />

        {/* Business Policies */}
      </TabPanel>

      <Box height={50} />
    </Container>
  );
};

export default EbayListingDetail;
