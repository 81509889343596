import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Paper,
  Box,
  Button,
} from '@mui/material';
import { Add, Delete, Undo } from '@mui/icons-material';
import {
  type IEbayDraftListing,
  type IEbayGroupItemDraftListing,
} from '../utils/TYPES';
import { set } from 'lodash';

const EditableAspectsSingleLists = ({
  aspects,
  setFormState,
}: {
  aspects: IEbayDraftListing['product']['product']['aspects'],
  setFormState: React.Dispatch<
    // eslint-disable-next-line prettier/prettier
    React.SetStateAction<IEbayDraftListing| null>
  >,
}) => {
  const [aspectEntries, setAspectEntries] = useState(Object.entries(aspects));

  useEffect(() => {
    setAspectEntries(Object.entries(aspects));
  }, [aspects]);

  const handleKeyChange = (index: number, newKey: string) => {
    const updatedEntries = [...aspectEntries];
    updatedEntries[index][0] = newKey;
    setAspectEntries(updatedEntries);
  };

  const handleValueChange = (index: number, newValue: string) => {
    const updatedEntries = [...aspectEntries];
    updatedEntries[index][1] = [newValue];
    setAspectEntries(updatedEntries);
  };

  const handleAddRow = () => {
    setAspectEntries([...aspectEntries, ['', ['']]]);
  };

  const handleDeleteRow = (index: number) => {
    const updatedEntries = aspectEntries.filter((_, i) => i !== index);
    setAspectEntries(updatedEntries);
  };

  const onSave = () => {
    if (!setFormState) return;
    setFormState((prev) => {
      if (!prev) return null;
      const sanitizedEntries = aspectEntries
        ?.map((entry) => entry?.[0] !== '' && entry?.[1]?.[0] !== '' && entry)
        .filter(Boolean) as Array<[string, string[]]>;
      setAspectEntries(sanitizedEntries);
      return {
        ...prev,
        product: {
          ...prev.product,
          product: {
            ...prev.product.product,
            aspects: Object.fromEntries(sanitizedEntries),
          },
        },
        specifications: Object.fromEntries(sanitizedEntries),
      };
    });
  };

  const revertChanges = () => {
    setAspectEntries(Object.entries(aspects));
  };

  const isFormUnChanged =
    JSON.stringify(aspects) ===
    JSON.stringify(Object.fromEntries(aspectEntries));

  return (
    <TableContainer sx={{ backgroundColor: 'surface.dark', height: 300 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Button
          onClick={() => {
            handleAddRow();
          }}
          sx={{ fontSize: 15, color: 'primary.main' }}
          startIcon={<Add />}
        >
          Add a row
        </Button>
        <Box>
          {!isFormUnChanged && (
            <IconButton
              onClick={() => {
                revertChanges();
              }}
              sx={{ fontSize: 15, color: 'primary.main' }}
            >
              <Undo />
            </IconButton>
          )}
          <Button
            onClick={onSave}
            variant="contained"
            disabled={isFormUnChanged}
            color="success"
          >
            accept changes
          </Button>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Aspect Key</TableCell>
            <TableCell>Aspect Value</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aspectEntries.map(([key, value], index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  value={key}
                  onChange={(e) => {
                    handleKeyChange(index, e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={value[0]}
                  onChange={(e) => {
                    handleValueChange(index, e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleDeleteRow(index);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditableAspectsSingleLists;
