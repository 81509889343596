import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { useReactiveVar } from '@apollo/client';
import { SelectedStoreState } from '../../stateManagement/SelectedStoreState';
import {
  ActiveMarketPlace,
  type ActiveMarketPlaceType,
} from '../../stateManagement/ActiveMarketPlace';
import { ActiveEbayPlaceId } from '../../stateManagement/ActiveEbayPlaceId';
import getEbayMarketPlaceId from '../helpers/getEbayMarketPlaceId';
import { type IOrganisationStore } from '../TYPES';

const db = getFirestore();
const useSynActiveStore = () => {
  const selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );

  useEffect(() => {
    if (selectedOrganisationAccountState?.activeStoreId) {
      const orgRef = doc(
        db,
        `organisations/${selectedOrganisationAccountState?.id}/stores/${selectedOrganisationAccountState?.activeStoreId}`,
      );

      const unsubscribe = onSnapshot(orgRef, (_doc) => {
        if (_doc.exists()) {
          SelectedStoreState({
            ...(_doc?.data() as Omit<IOrganisationStore, 'id'>),
            id: _doc?.id,
          });
          ActiveMarketPlace(
            _doc?.data()?.location as unknown as ActiveMarketPlaceType,
          );

          ActiveEbayPlaceId(
            getEbayMarketPlaceId(_doc?.data()?.location as string),
          );
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [selectedOrganisationAccountState?.activeStoreId]);
};

export default useSynActiveStore;
