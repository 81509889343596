import { Box, Grid2, Modal, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type ModalStateType } from '../../stateManagement/ModalStateManager';
import { ebayAuthUrl } from './TokenSateComponent';
import { Check } from '@mui/icons-material';
import CreditSystem from '../../pages/info/CreditSystem';

interface CreditSystemModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const CreditSystemModal = ({
  visible,
  onClose,
  modalState,
}: CreditSystemModalProps) => {
  const { palette } = useTheme();

  const [selectedStore, setSelectedStore] = React.useState<string>('');
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          height: '90vh',
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <CreditSystem />
      </Box>
    </Modal>
  );
};

export default CreditSystemModal;
