import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { type StepIconProps } from '@mui/material/StepIcon';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  List,
  Divider,
} from '@mui/material';
import EbayLogo from '../../../components/EbayLogo';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { useReactiveVar } from '@apollo/client';
import { AuthTokenState } from '../../../stateManagement/AuthTokenState';
import { getAuth } from 'firebase/auth';
import { ebayAuthUrl } from '../../../components/modals/TokenSateComponent';
import { Money } from '@mui/icons-material';
import EbayPolicies from './EbayPolicies';
import useEbaySettings from '../../../utils/hooks/useEbaySettings';
import EbayMerchantLocation from './EbayMerchantLocation';
import EbayFees from './EbayFees';
import { ModalStateManager } from '../../../stateManagement/ModalStateManager';
import { useNavigate } from 'react-router-dom';

const auth = getAuth();

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          color: '#784af4',
        },
      },
    ],
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean, active?: boolean },
}>(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const icons: Record<string, React.ReactElement<unknown>> = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <Money />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Connect to eBay',
  'Set up Policies',
  'Setup Merchant Location',
  'setup fees',
];

const benefits = [
  {
    title: 'Effortless Item Listing',
    description:
      'List your items directly to your eBay store with just a few clicks. No more manual entry – save time and avoid errors!',
  },
  {
    title: 'Optimized Product Titles',
    description:
      "Automatically refine your product titles to meet eBay's 80-character limit and improve search visibility.",
  },
  {
    title: 'Inventory Syncing',
    description:
      "Stay updated with real-time inventory and pricing adjustments to ensure you're always offering the best deals.",
  },
];

export default function SetupEbayAccount() {
  const [activeStep, setActiveStep] = React.useState(0);
  const { EBAY_AUTH_TOKEN } = useReactiveVar(AuthTokenState);

  const [createPolicyHere, setCreatePolicyHere] = React.useState(false);

  const { hasAllPolicies, hasMerchantLocation, hasAllSettings } =
    useEbaySettings();

  const Navigate = useNavigate();

  React.useEffect(() => {
    if (hasAllSettings && !EBAY_AUTH_TOKEN) {
      Navigate('/');
      ModalStateManager({
        type: 'EBAY_SETTING_COMPLETED',
      });
      return; // Exit early if all settings are completed
    }

    if (EBAY_AUTH_TOKEN) {
      setActiveStep(0);
    } else if (!EBAY_AUTH_TOKEN && hasAllPolicies && hasMerchantLocation) {
      setActiveStep(3);
    } else if (!EBAY_AUTH_TOKEN && hasAllPolicies) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  }, [EBAY_AUTH_TOKEN, hasAllPolicies, hasMerchantLocation, hasAllSettings]);
  return (
    <Container>
      <Stack sx={{ width: '100%' }} spacing={4} mt={10}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      {activeStep === 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            mt: 4,
          }}
        >
          <EbayLogo />
          <Card
            sx={{
              maxWidth: 600,
              margin: 'auto',
              mt: 4,
              boxShadow: 3,
              backgroundColor: 'surface.dark',
            }}
          >
            <CardHeader
              title="Why Connect Your eBay Account?"
              titleTypographyProps={{
                variant: 'h6',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            />
            <CardContent>
              <List>
                {benefits.map((benefit, index) => (
                  <ListItem
                    key={index}
                    sx={{ alignItems: 'flex-start', mb: 2 }}
                  >
                    <ListItemIcon>
                      <GridCheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={benefit.title}
                      secondary={benefit.description}
                      primaryTypographyProps={{ fontWeight: 'bold' }}
                      secondaryTypographyProps={{
                        fontSize: '0.9rem',
                        color: 'text.secondary',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
              <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    window.open(ebayAuthUrl, '_blank');
                  }}
                >
                  Connect Your eBay Account
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}

      {activeStep === 1 && (
        <Box>
          <Card
            sx={{
              maxWidth: 600,
              margin: 'auto',
              mt: 4,
              boxShadow: 3,
              backgroundColor: 'surface.dark',
            }}
          >
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                eBay Policies Made Easy
              </Typography>
              <Typography variant="body1" gutterBottom>
                To list your items on eBay, you`ll need the following policies:
              </Typography>
              <ul style={{ paddingLeft: '16px' }}>
                <li>
                  <Typography variant="body2">
                    <strong>Fulfillment Policy:</strong> How you`ll ship items.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    <strong>Return Policy:</strong> Your item return conditions.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    <strong>Payment Policy:</strong> Accepted payment methods.
                  </Typography>
                </li>
              </ul>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" gutterBottom>
                You can choose how to set up these policies:
              </Typography>
              <Stack spacing={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  href="https://www.ebay.com/bp/manage"
                  target="_blank"
                >
                  Go to eBay Business Policies
                </Button>
                <Typography variant="body2" align="center">
                  Or
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCreatePolicyHere(true);
                  }}
                >
                  Create Policies Right Here
                </Button>
              </Stack>
            </CardContent>
          </Card>
          <EbayPolicies />
        </Box>
      )}
      {activeStep === 2 && (
        <Box>
          <EbayMerchantLocation />
        </Box>
      )}
      {activeStep === 3 && (
        <Box>
          <EbayFees />
        </Box>
      )}
    </Container>
  );
}
