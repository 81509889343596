import React from 'react';
import Box from '../../components/Box';
import { Container, Typography } from '@mui/material';
import useLocalContent, {
  type LanguageContent,
  type ContentInterface,
  type ContentSection,
} from '../../utils/hooks/useLocalContent';
import COLORS from '../../utils/CONSTS/COLORS';
import Logo from '../../components/Logo';
import HeroSection from './componentes/HeroSection';
import SwitchLanguage from '../../components/SwitchLanguage';
import BrandsLogoSection from './componentes/BrandsLogoSection';
import { HOME_SCREEN_CONTENT } from '../../contents/HOME_SCREEN_CONTENT';
import { useNavigate } from 'react-router-dom';
import PricingComponent from '../../components/PricingComponent';
import ComparisonTable from '../../components/ComparisonTable';
import Footer from '../../components/Footer';

const HomeScreen = () => {
  const content: LanguageContent = useLocalContent(HOME_SCREEN_CONTENT);

  const header = content.header;
  const navigate = useNavigate();
  return (
    <Box minHeight={'100vh'} minWidth={'100vw'} className="bg-gradient">
      <Container>
        <Box pt={20} row center spaceBetween>
          <Logo size={200} />
          <Box row center>
            <a href="#pricing">
              <Box>
                <Typography
                  mr={2}
                  color={COLORS.WHITE}
                  variant="body1"
                  fontWeight={'600'}
                >
                  Pricing
                </Typography>
              </Box>
            </a>
            <Box
              onClick={() => {
                navigate('login');
              }}
            >
              <Typography
                mr={2}
                color={COLORS.WHITE}
                variant="body1"
                fontWeight={'600'}
              >
                {header?.login}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                navigate('register');
              }}
            >
              <Typography
                mr={2}
                color={COLORS.WHITE}
                variant="body1"
                fontWeight={'600'}
              >
                Sign up
              </Typography>
            </Box>
            <SwitchLanguage />
          </Box>
        </Box>
        <HeroSection content={content} />
      </Container>
      <div id="pricing">
        <Box
          backgroundColor={COLORS.WHITE}
          borderTopLeftRadius={30}
          borderTopRightRadius={30}
        >
          <BrandsLogoSection />
        </Box>

        <Box backgroundColor={COLORS.WHITE} center flex pb={30}>
          <ComparisonTable />
        </Box>
      </div>
      <Footer />
    </Box>
  );
};

export default HomeScreen;
