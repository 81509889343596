import { type TabPanelProps } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import React from 'react';

const TabPanel = (
  props: TabPanelProps & {
    children?: React.ReactNode,
    index: number,
    value: string,
  },
) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={Number(value) !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...(other as React.HTMLAttributes<HTMLDivElement>)}
    >
      {Number(value) === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
