import { getAuth } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import {
  UserCreditState,
  type UserCreditStateType,
} from '../../stateManagement/UserCreditState';
import { useReactiveVar } from '@apollo/client';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

const db = getFirestore();
const auth = getAuth();

export default () => {
  const userCredit = useReactiveVar(UserCreditState);
  const selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );
  const syncUserCredit = () => {
    const userCreditRef = doc(
      db,
      `organisations/${selectedOrganisationAccountState?.id}/credits/credits`,
    );

    onSnapshot(userCreditRef, (document) => {
      console.log('Current data: ', document.data());
      const raw: { mainCredits?: number, extraCredits?: number } =
        document.data() ?? {};
      const userCreditState: UserCreditStateType = {
        mainCredit: Number(raw?.mainCredits ?? 0),
        extraCredit: Number(raw?.extraCredits ?? 0),
        totalCredit:
          Number(raw.mainCredits ?? 0) + Number(raw.extraCredits ?? 0),
      };
      UserCreditState(userCreditState);
    });
  };
  return {
    userCredit,
    syncUserCredit,
  };
};
