import { Button, Grid2, Typography, useTheme } from '@mui/material';
import React from 'react';
import { type AmazonProductType } from '../utils/TYPES';
import Box from './Box';
import createAffiliateLink from '../utils/helpers/createAffiliateLink';
import CopyText from './CopyText';
import { truncate } from 'lodash';
import useIsInCompareList from '../utils/hooks/useIsInCompareList';
import { Add, DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface ProductCardTemplateProps {
  addRemoveProductToCompareList?: (product: AmazonProductType) => void;
  hasActionButton?: boolean;
  compareListLoading?: boolean;
  id: string;
  productLink?: string;
  productImage: string;
  salesVolume?: string;
  productTitle: string;
  productPrice: string;
  currency: string;
  isAEProduct?: boolean;
  createDraftFromAETaskManager?: (productId: string) => void;
}

const ProductCardTemplate = ({
  id,
  productLink,
  addRemoveProductToCompareList,
  hasActionButton,
  compareListLoading,
  productImage,
  salesVolume,
  productTitle,
  productPrice,
  currency,
  createDraftFromAETaskManager,
  isAEProduct,
}: ProductCardTemplateProps) => {
  const { palette } = useTheme();
  return (
    <Grid2 container sx={{ mt: 2 }}>
      <Grid2 size={4}>
        <a href={productLink ?? ''} target="_blank" rel="noreferrer">
          <img
            src={productImage}
            alt="product"
            loading="lazy"
            style={{
              width: '150px',
              height: '150px',
              maxHeight: '100%',
              borderRadius: 8,
            }}
          />
        </a>
      </Grid2>
      <Grid2 size={5} sx={{ pl: 2 }}>
        <Typography sx={{ opacity: 0.7 }}>{productTitle}</Typography>
        {/* text={truncate(productTitle, { length: 50, separator: ' ' }) ?? ''}
          concatLength={70}
          color={palette?.primary?.main}
        /> */}
        {salesVolume && (
          <Typography mt={2} sx={{ opacity: 0.7 }}>
            last sales {truncate(salesVolume, { length: 50, separator: ' ' })}
          </Typography>
        )}
      </Grid2>

      <Grid2 size={3} sx={{ pl: 2 }}>
        <Typography fontSize={17} mb={1}>
          {productPrice} {currency}
        </Typography>
        {hasActionButton && (
          <LoadingButton
            variant="contained"
            onClick={() => {
              createDraftFromAETaskManager && createDraftFromAETaskManager(id);
            }}
          >
            Create Ebay Draft
          </LoadingButton>
        )}
      </Grid2>
    </Grid2>
  );
};

export default ProductCardTemplate;
