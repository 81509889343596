/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export interface IEbaySellerProfileInfoDataType {
  userId: string;
  email: string;
  feedbackScore: string;
  eBayGoodStanding: string;
  registrationDate: string;
  site: string;
  storeOwner: string;
  sellerBusinessType: string;
}

export interface IEbaySellerProfileInfoStateType {
  loading: boolean;
  data?: IEbaySellerProfileInfoDataType;
}

export const EbaySellerProfileInfoState =
  makeVar<IEbaySellerProfileInfoStateType>({
    loading: false,
    data: undefined,
  });
