import * as React from 'react';
import useSyncManagedUsersProfile from '../utils/hooks/useSyncManagedUsersProfile';
import { Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';
import { isStipeTest } from '../utils/CONSTS/SUBSCRIPTION_PRODUCTS';
import Box from '../components/Box';
import COLORS from '../utils/CONSTS/COLORS';
import { useSyncMarketPlace } from '../stateManagement/ActiveMarketPlace';
import { useTokenValidation } from '../stateManagement/useTokenValidation';
import useCompareList from '../utils/hooks/useCompareList';
import useSyncEbaySellerProfileInfo from '../utils/hooks/useSyncEbaySellerProfileInfo';
import useSynActiveStore from '../utils/hooks/useSynActiveStore';

const LoggedInAppLayout = () => {
  useSyncManagedUsersProfile();
  // useSyncMarketPlace();

  useSynActiveStore();
  const validate = useTokenValidation();
  React.useEffect(() => {
    validate();
  }, []);

  const { syncAllCompareLists } = useCompareList({});
  const { syncSellerProfileInfo } = useSyncEbaySellerProfileInfo();

  React.useEffect(() => {
    syncAllCompareLists();
    syncSellerProfileInfo();
  }, []);
  return (
    <div>
      {/* <Box center backgroundColor={COLORS.ERROR}>
        <Typography textAlign={'center'} variant="h6">
          {isStipeTest ? 'THIS IS THE TEST ENVIRONMENT' : ''}
        </Typography>
      </Box> */}
      <Outlet />
    </div>
  );
};

export default LoggedInAppLayout;
