/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import Box from '../Box';
import SPACES from '../../utils/CONSTS/SPACES';
import { useFirebaseUpload } from '../../utils/hooks/useFirebaseUpload';
import { LoadingButton } from '@mui/lab';
import { Add, Compare } from '@mui/icons-material';
import { CompareListState } from '../../stateManagement/CompareListState';
import { useReactiveVar } from '@apollo/client';
import { log } from 'console';
import useLocalContent, {
  LanguageContent,
} from '../../utils/hooks/useLocalContent';
import { COMPARE_LIST_CONTENT } from '../../contents/COMPARE_LIST_CONTENT';

interface StartCompareListModalProps {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  createComparList: (name: string) => Promise<string | undefined>;
  setCompareListAsActive: (id: string) => void;
}

const StartCompareListModal = ({
  isOpen,
  onClose,
  loading,
  createComparList,
  setCompareListAsActive,
}: StartCompareListModalProps) => {
  const theme = useTheme();

  const [name, setName] = React.useState('');

  const { compareLists } = useReactiveVar(CompareListState);

  console.log('compareLists', compareLists);

  const { CONTENT } = useLocalContent(COMPARE_LIST_CONTENT);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="modal"
        backgroundColor={theme.palette.background.default}
        width="720px"
        pAll={SPACES?.SPACE_XXL}
        relative
        borderRadius={SPACES?.SPACE_S}
      >
        <Box column height={'100%'} width={'100%'} flex center>
          <Typography variant="h6">{CONTENT?.CREATE_NEW_LIST}</Typography>
          <Box row>
            <TextField
              label="List Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              sx={{ mt: 2 }}
            />
            <LoadingButton
              onClick={async () => {
                const id = await createComparList(name);
                if (id) setCompareListAsActive(id);
                setName('');
                onClose();
              }}
              variant="contained"
              loading={loading}
              disabled={!name}
              sx={{ mt: 2 }}
            >
              Start
            </LoadingButton>
          </Box>
          {!!compareLists?.length && (
            <Box>
              <Box mt={20} mb={10}>
                <Typography textAlign={'center'} variant="h6">
                  {CONTENT?.SELECT_FROM_EXISTING}
                </Typography>
              </Box>
              <Box
                flex={1}
                column
                style={{
                  maxHeight: '50vh',
                  overflow: 'scroll',
                }}
              >
                {!!compareLists?.length &&
                  compareLists?.map((list) => {
                    return (
                      <Box
                        key={list.id}
                        onClick={() => {
                          setCompareListAsActive(list.id);
                          onClose();
                        }}
                        row
                        spaceBetween
                        width="25vw"
                        pAll={SPACES?.SPACE_S}
                        backgroundColor={theme.palette.surface.main}
                        mb={SPACES?.SPACE_S}
                        borderRadius={SPACES?.SPACE_XXS}
                        style={{ cursor: 'pointer' }}
                      >
                        <Typography fontWeight={'bold'}>{list.name}</Typography>
                        <Typography>
                          {list?.dateCreated ? list?.dateCreated : ''}
                        </Typography>
                        <Typography>
                          {list?.products?.length
                            ? `${list?.products?.length} items`
                            : 'new'}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default StartCompareListModal;
