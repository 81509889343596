import React from 'react';
import AmazonImageLight from '../images/amazon-logo-light.png';
import AmazonImageDark from '../images/amazon-logo-dark.png';

import Box from './Box';
import { useReactiveVar } from '@apollo/client';
import {
  DarkModeStateManager,
  useDarkModeState,
} from '../stateManagement/DarkModeStateManagement';

interface AmazonLogoProps {
  size?: number;
}

const AmazonLogo = ({ size = 110 }: AmazonLogoProps) => {
  const [darkmode] = useDarkModeState();
  return (
    <Box flex center height={size / 2} width={size}>
      <img
        style={{ width: size, height: 'auto' }}
        src={darkmode ? AmazonImageLight : AmazonImageDark}
        alt={'logo'}
      />
    </Box>
  );
};

export default AmazonLogo;
