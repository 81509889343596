import React from 'react';
import TabPanel from './TabPanel';
import {
  Box,
  Button,
  capitalize,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { type CSS } from 'styled-components/dist/types';
import {
  type AmazonProductType,
  type IEbayDraftListingVariations,
} from '../utils/TYPES';
import { Delete, DeleteOutline, Edit } from '@mui/icons-material';
import { useModalStateManager } from '../stateManagement/ModalStateManager';
import { type ActiveEbayPlaceIdType } from '../stateManagement/ActiveEbayPlaceId';

interface EbayVariationsProps {
  tabValue: number;
  ebayDraftListingVariations: IEbayDraftListingVariations[];
  itemContainerStyles: React.CSSProperties;
  id: string | undefined;
  marketPlaceId: ActiveEbayPlaceIdType | undefined;
}

const EbayVariations = ({
  tabValue,
  ebayDraftListingVariations,
  itemContainerStyles,
  id,
  marketPlaceId,
}: EbayVariationsProps) => {
  const { direction } = useTheme();
  const [modalState, updateModal] = useModalStateManager();
  return (
    <TabPanel value={String(tabValue)} index={1} dir={direction}>
      <Box>
        {!!ebayDraftListingVariations?.length &&
          ebayDraftListingVariations?.map((variation, index) => {
            return (
              <Grid container key={variation?.id} style={itemContainerStyles}>
                <Grid item xs={2}>
                  <img
                    src={variation?.product?.product?.imageUrls[0]}
                    alt="product"
                    style={{ width: 100, height: 'auto' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {variation?.product?.product?.aspects &&
                    Object.entries(variation?.product?.product?.aspects)?.map(
                      ([key, value]) => {
                        return (
                          <Typography key={key} gutterBottom>
                            {capitalize(key)}: {value?.[0]}
                          </Typography>
                        );
                      },
                    )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography gutterBottom>
                    {variation?.offer?.pricingSummary?.price?.value}{' '}
                    {variation?.offer?.pricingSummary?.price?.currency}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button>
                    <DeleteOutline color="error" />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      updateModal({
                        open: true,
                        type: 'EBAY_LISTING_VARIATION_PRICE',
                        data: {
                          id,
                          variation,
                          marketPlaceId,
                        },
                      });
                    }}
                    startIcon={<Edit />}
                  >
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </TabPanel>
  );
};

export default EbayVariations;
