import { getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import React, { useEffect, useMemo } from 'react';
import { type IEbaySettings } from '../TYPES';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { useReactiveVar } from '@apollo/client';
import EBAY_SETTING_INITIAL from '../CONSTS/EBAY_SETTING_INITIAL';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

const db = getFirestore();
const auth = getAuth();
const useEbaySettings = () => {
  const [ebaySettings, setEbaySettings] = React.useState<
    // eslint-disable-next-line prettier/prettier
    IEbaySettings | null | undefined
  >(undefined);

  const selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );

  console.log(
    'selectedOrganisationAccountState',
    selectedOrganisationAccountState,
  );

  const activeMarketPlace = useReactiveVar(ActiveMarketPlace);
  const getEbaySettings = async () => {
    console.log('getEbaySettings');
    const settingsReference = doc(
      db,
      `organisations/${selectedOrganisationAccountState?.id}/stores/${selectedOrganisationAccountState?.activeStoreId}/settings/config`,
    );
    onSnapshot(settingsReference, (snapshot) => {
      const data = {
        ...EBAY_SETTING_INITIAL,
        ...snapshot.data(),
      };
      setEbaySettings(data as IEbaySettings);
    });
  };

  useEffect(() => {
    if (activeMarketPlace) getEbaySettings();
  }, [activeMarketPlace]);

  const saveEbaySettings = async (settings: IEbaySettings) => {
    const settingsReference = doc(
      db,
      `organisations/${selectedOrganisationAccountState?.id}/stores/${selectedOrganisationAccountState?.activeStoreId}/settings/config`,
    );
    await setDoc(settingsReference, settings, { merge: true });
    setEbaySettings(settings);
  };

  const hasAllPolicies = useMemo(() => {
    if (!ebaySettings?.policies) {
      return false;
    }
    return (
      ebaySettings?.policies?.returnPolicyId &&
      ebaySettings?.policies?.fulfillmentPolicyId &&
      ebaySettings?.policies?.paymentPolicyId
    );
  }, [ebaySettings]);

  const hasMerchantLocation = useMemo(() => {
    if (!ebaySettings?.location) {
      return false;
    }
    return ebaySettings?.location?.merchantLocationKey;
  }, [ebaySettings]);

  const hasFees = useMemo(() => {
    if (!ebaySettings?.fees) {
      return false;
    }
    return (
      ebaySettings?.fees?.profitPercentage &&
      ebaySettings?.fees?.platformFeePercentage &&
      ebaySettings?.fees?.fixedFees
    );
  }, [ebaySettings]);

  const hasAllSettings = useMemo(() => {
    return hasAllPolicies && hasMerchantLocation && hasFees;
  }, [hasAllPolicies, hasMerchantLocation, hasFees]);

  return {
    getEbaySettings,
    ebaySettings,
    saveEbaySettings,
    hasAllPolicies,
    hasMerchantLocation,
    hasAllSettings,
  };
};

export default useEbaySettings;
