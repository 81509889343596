import * as React from 'react';
import Box from './Box';
import COLORS from '../utils/CONSTS/COLORS';
import { helix } from 'ldrs';
import { Container, Paper } from '@mui/material';
import robotAnimation from '../images/logo_animation.gif';

helix.register();

// Default values shown

interface LoadingProps {
  size?: number;
  height?: string | number;
}

const Loading = ({ size = 150, height }: LoadingProps) => {
  return (
    <Container>
      <Box center flex column height={size + 5}>
        <span>
          {/* <l-helix
            size={size}
            stroke-length="0.25"
            bg-opacity="0.1"
            speed="0.9"
            color={COLORS?.PRIMARY}
          /> */}
          <img
            src={robotAnimation}
            alt="robot"
            style={{
              width: size,
              height: size,
              transform: `scale(1)`,
              animation: 'pulse 4s infinite',
              animationTimingFunction: 'cubic-bezier(4,-14, 4, 25)',
            }}
          />
        </span>
      </Box>
    </Container>
  );
};

export default Loading;
