import React from 'react';
import AmazonImage from '../images/ebay-logo.png';
import Box from './Box';

interface EbayLogoProps {
  size?: number;
}

const EbayLogo = ({ size = 110 }: EbayLogoProps) => {
  return (
    <Box center height={size / 2} width={size} overflow="hidden">
      <img
        style={{ width: size, height: size, marginTop: -(size / 4) }}
        src={AmazonImage}
        alt={'logo'}
      />
    </Box>
  );
};

export default EbayLogo;
