/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button,
  Container,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '../../components/Box';
import Loading from '../../components/Loading';
import SPACES from '../../utils/CONSTS/SPACES';
import { useNavigate } from 'react-router-dom';
import useGetComparedRecords from '../../utils/hooks/useGetComparedRecords';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteForever from '@mui/icons-material/DeleteForever';

import COLORS from '../../utils/CONSTS/COLORS';
import { values } from 'lodash';
import ProgressBarTemplate from '../../components/ProgressBarTemplate';
import LinearProgressWithLabel from '../../components/LinearProgress';
import { Check } from '@mui/icons-material';
import moment, { type MomentInput } from 'moment';

const ComparesLandingPage = (): JSX.Element | null => {
  const navigate = useNavigate();

  const { palette } = useTheme();

  const {
    comparedRecords,
    makeFavorite,
    deleteRecordWithId,
    hasNextPage,
    getNextPage,
  } = useGetComparedRecords({});

  if (comparedRecords && !comparedRecords?.length)
    return <Loading size={100} />;

  return (
    <Container fixed>
      <Box pt={SPACES.SPACE_L}>
        {hasNextPage && (
          <Box>
            <Button onClick={() => getNextPage('next')}>Next</Button>
          </Box>
        )}

        {comparedRecords?.length &&
          comparedRecords?.map((record) => (
            <CompareRecord
              key={record?.id}
              record={record}
              deleteRecordWithId={deleteRecordWithId}
              makeFavorite={makeFavorite}
              navigate={navigate}
              palette={palette}
            />
          ))}
      </Box>
    </Container>
  );
};

interface CompareRecordProps {
  record: any;
  deleteRecordWithId: (id: string) => void;
  makeFavorite: (id: string, isFavorite: boolean) => void;
  navigate: (url: string) => void;
  palette: any;
}

const CompareRecord = ({
  record,
  deleteRecordWithId,
  makeFavorite,
  navigate,
  palette,
}: CompareRecordProps) => {
  // add all the progress values
  const progress = values(record?.progress).reduce(
    (acc: number, value: number) => {
      return value + acc;
    },
    0,
  );

  const progressPercentage =
    (progress / record?.['amazon products']?.length) * 100;

  console.log('product length', progressPercentage);

  const isCompleted = progressPercentage === 100;
  return (
    <Box
      row
      key={record?.id}
      flex={1}
      center
      pr={SPACES.SPACE_XS}
      pl={SPACES.SPACE_XS}
      style={{
        backgroundColor: palette?.surface?.dark,
        borderRadius: 2,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        paddingBottom: 2,
        overflow: 'hidden',
        marginBottom: 1,
        justifyContent: 'space-between',
        opacity: isCompleted ? 1 : 0.5,
      }}
    >
      <Box
        onClick={() => {
          makeFavorite(record?.id as string, !!record?.isFavorite);
        }}
        pAll={SPACES.SPACE_XS}
      >
        {record?.isFavorite ? (
          <FavoriteIcon
            style={{
              color: COLORS.ERROR,
            }}
          />
        ) : (
          <FavoriteOutlinedIcon
            style={{
              color: COLORS.ERROR,
            }}
          />
        )}
      </Box>
      <Box
        row
        spaceBetween
        onClick={() => {
          if (isCompleted) navigate(`/compare/${record?.id}`);
        }}
        width="80%"
        center
        style={{ cursor: isCompleted ? 'pointer' : 'default' }}
      >
        <Box width={'40%'}>
          <Typography variant="h6">{record?.['search term']}</Typography>
        </Box>
        {isCompleted && (
          <Typography variant="subtitle2">
            {moment(record?.date.toDate() as MomentInput).format(
              'MM/DD/YYYY hh:mm a',
            )}
          </Typography>
        )}
        <Typography variant="subtitle2">
          {record?.['amazon products']?.length}
        </Typography>
        {isCompleted ? (
          <Typography color="success">
            <Check /> Completed
          </Typography>
        ) : (
          <LinearProgressWithLabel value={progressPercentage} />
        )}
      </Box>
      <Box
        onClick={() => {
          deleteRecordWithId(record?.id as string);
        }}
        pl={SPACES.SPACE_XS}
      >
        <DeleteForever color="error" />
      </Box>
    </Box>
  );
};

export default ComparesLandingPage;
