/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';

const functions = getFunctions();

const useAEAPI = (region: string) => {
  const searchAEByImage = async (imageUrl: string) => {
    const search = httpsCallableFromURL(
      functions,
      firebaseCall('searchAliExpressWithImage'),
    );
    try {
      // const response = await axios.post('http://localhost:3001/search-ae-by-image', {
      //     region,
      //     imageUrl
      // });

      const response = await search({
        region,
        imageUrl,
      });

      console.log('API Response IMAGE AE:', response);
      return response.data;
    } catch (err: any) {
      console.error(
        'Error getting token:',
        err.response ? err.response.data : err.message,
      );
    }
  };

  const getAEProductByProductId = async (productId: string) => {
    const search = httpsCallableFromURL(
      functions,
      firebaseCall('getAEProductByProductId'),
    );
    try {
      const response = await search({
        productId,
      });

      console.log('API Response PRODUCT AE:', response);
      return response.data;
    } catch (err: any) {
      console.error(
        'Error getting token:',
        err.response ? err.response.data : err.message,
      );
    }
  };

  return { searchAEByImage, getAEProductByProductId };
};

export default useAEAPI;
