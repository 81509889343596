import React from 'react';

import { Box, Modal, Typography, useTheme } from '@mui/material';
import { type AmazonAIBestSellerDiscovererType } from '../../utils/hooks/useAmazonAIBestSellerDiscoverer';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';

interface ProductSuggestionReasonModalProps {
  open: boolean;
  onClose: () => void;
  product: AmazonAIBestSellerDiscovererType | undefined;
}

const ProductSuggestionReasonModal: React.FC<
  // eslint-disable-next-line prettier/prettier
  ProductSuggestionReasonModalProps
> = ({ open, onClose, product }) => {
  const { palette } = useTheme();
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.surface?.dark,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          p: 3,
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            width: '100%',
            pb: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={product?.product_photo}
            alt={product?.product_title}
            style={{ width: '35% ', height: 'auto' }}
          />
          <Typography fontWeight={'800'} sx={{ ml: 3 }}>
            {product?.product_title}
          </Typography>
        </Box>
        <Typography variant="h6">Reason: </Typography>
        <Typography>{product?.reason}</Typography>
      </Box>
    </Modal>
  );
};

export default ProductSuggestionReasonModal;
