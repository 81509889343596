/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export interface IEbayPolicy {
  fulfillmentPolicies:
    | Array<{
        categoryTypes: Array<{
          name: 'ALL_EXCLUDING_MOTORS_VEHICLES' | 'MOTORS_VEHICLES',
          default: boolean,
        }>,
        handlingTime: {
          /* TimeDuration */
          unit: 'DAY' | 'YEAR' | 'MONTH' | 'WEEK' | 'BUSINESS_DAY',
          value: number,
        },
        globalShipping?: boolean,
        fulfillmentPolicyId?: string,
        name: string,
        marketplaceId: string,
        shippingOptions: Array<{
          costType: 'FLAT_RATE' | 'CALCULATED',
          optionType: 'DOMESTIC' | 'INTERNATIONAL',
          shippingDiscountProfileId?: string,
          shippingPromotionOffered?: boolean,
          shippingServices: Array<{
            additionalShippingCost?: {
              currency: string,
              value: number,
            },
            buyerResponsibleForPickup: boolean,
            buyerResponsibleForShipping: boolean,
            freeShipping: boolean,
            shippingCost: {
              currency: string,
              value: number,
            },
            shippingServiceCode?: string,
            shippingCarrierCode:
              | 'GENERIC'
              | 'UPSG'
              | 'USPS'
              | 'FEDEX'
              | 'DEUTSCHE_POST'
              | 'DHL'
              | 'HERMES'
              | 'DPD'
              | 'GLS'
              | 'ROYAL_MAIL'
              | 'PARCELFORCE'
              | 'YODEL'
              | 'OTHER',
            sortOrder?: number,
          }>,
        }>,
      }>
    | [];
  returnPolicies:
    | Array<{
        categoryTypes: Array<{
          name: 'ALL_EXCLUDING_MOTORS_VEHICLES' | 'MOTORS_VEHICLES',
          default: boolean,
        }>,
        marketplaceId: string,
        name: string,
        refundMethod?: 'MONEY_BACK' | 'EXCHANGE',
        returnMethod?: 'MONEY_BACK',
        returnPeriod?: {
          unit?: 'DAY' | 'MONTH | BUSINESS_DAY',
          value?: number,
        },
        returnPolicyId?: string,
        returnShippingCostPayer?: 'BUYER' | 'SELLER',
        returnsAccepted: boolean,
      }>
    | [];
  paymentPolicies:
    | Array<{
        categoryTypes: Array<{
          name: 'ALL_EXCLUDING_MOTORS_VEHICLES' | 'MOTORS_VEHICLES',
          default: boolean,
        }>,
        marketplaceId: string,
        name: string,
        immediatePay: boolean,
        paymentPolicyId?: string,
        returnPolicyId?: string,
        paymentMethods?: Array<'PAYPAL' | 'CREDIT_CARD' | 'PAY_UPON_INVOICE'>,
      }>
    | [];
}

export const EbayPolicyState = makeVar<IEbayPolicy>({
  fulfillmentPolicies: [],
  returnPolicies: [],
  paymentPolicies: [],
});
