import React from 'react';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  Link,
  CssBaseline,
} from '@mui/material';

// Define types for the sections and their points
interface Section {
  title: string;
  points: string[];
}

const termsAndConditions: Section[] = [
  {
    title: 'Introduction',
    points: [
      'DropCompare provides tools and services for product discovery, comparison, and insights, particularly for e-commerce and online sellers.',
      'These Terms apply to all visitors, users, and others who access or use our platform.',
    ],
  },
  {
    title: 'Use of Our Services',
    points: [
      'You agree to use DropCompare for lawful purposes only and in accordance with these Terms.',
      'You will not violate any applicable local, national, or international laws.',
      'You will not attempt to hack, reverse-engineer, or interfere with the platform.',
      'You must not attempt to abuse the credit system or automate requests that overload our platform.',
    ],
  },
  {
    title: 'Account and Subscription',
    points: [
      'To access certain features, you must create an account.',
      'You agree to provide accurate and complete information when registering.',
      'You are responsible for maintaining the confidentiality of your login details.',
      'Subscriptions are billed monthly, and unused credits in the free tier do not roll over. Paid credits will remain valid as long as your account is active.',
    ],
  },
  {
    title: 'Intellectual Property Rights',
    points: [
      'DropCompare retains ownership of all platform features, content, logos, and AI models.',
      'Users may not copy, distribute, or modify any part of DropCompare without explicit consent.',
      'All rights, including copyrights, trademarks, and proprietary technology, remain with DropCompare.',
    ],
  },
  {
    title: 'User Content',
    points: [
      'By submitting any data or content to DropCompare, you grant DropCompare a non-exclusive, royalty-free license to use your content to provide services.',
      'You confirm that you have the right to submit such content and it does not infringe on third-party rights.',
    ],
  },
  {
    title: 'Limitation of Liability',
    points: [
      "DropCompare is provided 'as is' and 'as available'.",
      'We do not guarantee that the platform will operate uninterrupted, error-free, or secure.',
      'DropCompare shall not be liable for any indirect, incidental, or consequential damages (e.g., lost profits, business interruptions) arising out of or related to your use of the platform.',
      'Users are responsible for verifying product availability and pricing through their own due diligence.',
    ],
  },
  {
    title: 'Data Protection and Privacy',
    points: [
      'We comply with GDPR and take user privacy seriously.',
      'Our full privacy policy outlines what personal data we collect, how we use, store, and protect your data, and your rights to access, correct, and delete your data.',
      'For details, please refer to our Privacy Policy.',
    ],
  },
  {
    title: 'Termination',
    points: [
      'We reserve the right to terminate or suspend your account immediately, without prior notice, if you breach these Terms.',
    ],
  },
  {
    title: 'Governing Law',
    points: [
      'These Terms are governed by and construed in accordance with the laws of the United Kingdom.',
      'Any disputes will be subject to the exclusive jurisdiction of the courts of London, UK.',
    ],
  },
  {
    title: 'Contact Us',
    points: [
      'If you have any questions about these Terms and Conditions, please contact us:',
      'Email: info@dropcompare.com',
    ],
  },
];

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: '#f9f9f9',
          color: '#333',
          borderRadius: 2,
          padding: 4,
          mt: 4,
          boxShadow: 1,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            textAlign="center"
            color="primary"
          >
            Terms and Conditions
          </Typography>
          {termsAndConditions.map((section, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                {section.title}
              </Typography>
              <List>
                {section.points.map((point, pointIndex) => (
                  <ListItem key={pointIndex} sx={{ pl: 2 }}>
                    <Typography variant="body1">{point}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
          <Typography
            variant="body2"
            textAlign="center"
            color="text.secondary"
            sx={{ mt: 4 }}
          >
            Last Updated: January 18, 2025
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsAndConditions;
