import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';

const createAffiliateLink = (productId: string) => {
  let baseURL = '';
  if (ActiveMarketPlace() === 'US') {
    baseURL = `https://www.amazon.com/dp/${productId}`;
  } else baseURL = `https://www.amazon.co.uk/gp/product/${productId}`;
  const affiliateTag = 'dropcompare-21'; // Your Amazon Affiliate tag
  const additionalParams = {
    linkCode: 'll1',
    tag: affiliateTag,
    linkId: '74bda069733d216d9193fb299b029fe1',
    language: 'en_US',
    ref_: 'as_li_ss_tl',
  };

  const queryParams = new URLSearchParams(additionalParams).toString();
  return `${baseURL}?${queryParams}`;
};

export default createAffiliateLink;
