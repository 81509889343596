interface EbayProfitInput {
  profit?: number | null; // Desired profit (optional, required if sellingPrice is null)
  sellingPrice?: number | null; // Selling price (optional, required if profit is null)
  purchasePrice: number; // Cost of the product
  shippingCost: number; // Cost of shipping
  isPrivateSeller?: boolean; // Indicates if the seller is private (default: true)
  isUK?: boolean; // Indicates if the marketplace is in the UK (default: true)
  ebayFeePercentage?: number; // eBay fee percentage (default: 12.9%)
  paypalFeePercentage?: number; // PayPal fee percentage (default: 2.9%)
  fixedPaypalFee?: number; // Fixed PayPal fee (default: $0.30)
}

export const calculateEbayProfitOrSellingPrice = ({
  profit = null,
  sellingPrice = null,
  purchasePrice,
  shippingCost,
  isPrivateSeller = true,
  isUK = true,
  ebayFeePercentage = 12.9,
  paypalFeePercentage = 2.9,
  fixedPaypalFee = 0.3,
}: EbayProfitInput) => {
  // If neither selling price nor profit is provided, return an error
  if (profit === null && sellingPrice === null) {
    throw new Error("Either 'profit' or 'sellingPrice' must be provided.");
  }

  // Helper function to calculate eBay fees
  const calculateEbayFee = (price: number) => {
    return isPrivateSeller && isUK ? 0 : price * (ebayFeePercentage / 100);
  };

  // Helper function to calculate PayPal fees
  const calculatePaypalFee = (price: number) => {
    return price * (paypalFeePercentage / 100) + fixedPaypalFee;
  };

  if (sellingPrice !== null) {
    // Calculate profit if selling price is given
    const ebayFee = calculateEbayFee(sellingPrice);
    const paypalFee = calculatePaypalFee(sellingPrice);
    const totalCosts = purchasePrice + shippingCost + ebayFee + paypalFee;
    const calculatedProfit = sellingPrice - totalCosts;

    console.log('calculatedProfit sellingPrice', sellingPrice);

    return {
      sellingPrice: sellingPrice?.toFixed(2),
      purchasePrice: purchasePrice?.toFixed(2),
      shippingCost: shippingCost?.toFixed(2),
      ebayFee: ebayFee?.toFixed(2),
      paypalFee: paypalFee?.toFixed(2),
      totalCosts: totalCosts?.toFixed(2),
      profit: calculatedProfit?.toFixed(2),
    };
  } else if (profit !== null) {
    console.log('profit IM GETTNIN CALLED', profit);
    // Calculate selling price if profit is given
    let estimatedSellingPrice: number = purchasePrice + shippingCost;
    let finalSellingPrice: number;

    // Use iterative approach to estimate selling price
    do {
      const ebayFee = calculateEbayFee(estimatedSellingPrice);
      const paypalFee = calculatePaypalFee(estimatedSellingPrice);
      const totalCosts = purchasePrice + shippingCost + ebayFee + paypalFee;
      finalSellingPrice = totalCosts + profit;
      estimatedSellingPrice += 0.01; // Adjust estimation
    } while (Math.abs(finalSellingPrice - estimatedSellingPrice) > 0.01);

    const ebayFee = calculateEbayFee(finalSellingPrice);
    const paypalFee = calculatePaypalFee(finalSellingPrice);
    const totalCosts =
      purchasePrice + shippingCost + ebayFee + paypalFee + profit;
    console.log('totalCosts', totalCosts);

    return {
      sellingPrice: finalSellingPrice.toFixed(2),
      purchasePrice: purchasePrice.toFixed(2),
      shippingCost: shippingCost.toFixed(2),
      ebayFee: ebayFee.toFixed(2),
      paypalFee: paypalFee.toFixed(2),
      totalCosts: totalCosts.toFixed(2),
      profitPercentage: profit.toFixed(2),
    };
  }
};
