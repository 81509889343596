/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { type AmazonProductType } from '../utils/TYPES';

export interface OrganisationAccountType {
  name: string;
  id: string;
  dateCreated: string;
  activeStoreId: string;
}

export const OrganisationAccounts = makeVar<
  // eslint-disable-next-line prettier/prettier
  OrganisationAccountType[] | undefined
>(undefined);
