/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ModalStateManager } from '../../../stateManagement/ModalStateManager';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../../utils/hooks/useEbayInventoryItem';
import { useReactiveVar } from '@apollo/client';
import { EbayPolicyState } from '../../../stateManagement/EbayPolicyState';
import { EbayInventoryLocationsState } from '../../../stateManagement/EbayInventoryLocationsState';
import { type IEbaySettings } from '../../../utils/TYPES';
import useEbaySettings from '../../../utils/hooks/useEbaySettings';
import { cloneDeep } from 'lodash';

const EbayFees = () => {
  const { palette } = useTheme();
  const {
    listInventoryItemOnEbay,
    createInventoryLocation,
    createReturnPolicy,
    getReturnPolicy,
    getFulfillmentPolicy,
    getPaymentPolicy,
    createFulfillmentPolicy,
    createPaymentPolicy,
    getInventoryLocations,
  } = useEbayInventoryItem();

  useEffect(() => {
    // update inventory Location in database
    getInventoryLocations();
  }, []);

  const marketPlaceId = 'EBAY_US';

  const { ebaySettings, saveEbaySettings } = useEbaySettings();
  const [formState, setFormState] = React.useState<IEbaySettings | null>({
    fees: {
      profitPercentage: 50,
      platformFeePercentage: 13,
      fixedFees: 0.3,
      roundCentTo99: false,
    },
  });
  console.log('formState', formState);

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;
    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: IEbaySettings | null) => {
      const updatedState = { ...prevState };
      let current: Record<string, any> = updatedState;

      for (let i = 0; i < path.length - 1; i++) {
        const key = path[i];

        // Check if the key exists, if not, create an empty object
        if (!current[key]) {
          current[key] = {};
        }

        current = current[key];
      }

      // Set the final value
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value;

      return updatedState; // Return the updated state
    });
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ textDecoration: 'underline', mb: 2 }}>
        FREES & PRICING
      </Typography>
      <Grid container spacing={2} sx={{ alignItems: 'center', mb: 2 }}>
        <Grid item xs={4}>
          <TextField
            sx={{ backgroundColor: palette?.background?.default }}
            label="Your Profit %"
            type="number"
            fullWidth
            value={Number(formState?.fees?.profitPercentage)}
            margin="normal"
            onChange={(e) => {
              handleChange(e, ['fees', 'profit']);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            sx={{ backgroundColor: palette?.background?.default }}
            label="Platform Fee %"
            fullWidth
            type="number"
            value={Number(formState?.fees?.platformFeePercentage?.toFixed(2))}
            margin="normal"
            onChange={(e) => {
              handleChange(e, ['fees', 'platformFee']);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            sx={{ backgroundColor: palette?.background?.default }}
            label="Fixed Fee"
            type="number"
            fullWidth
            value={Number(formState?.fees?.fixedFees).toFixed(2)}
            margin="normal"
            onChange={(e) => {
              handleChange(e, ['fees', 'fixedFees']);
            }}
            inputMode="decimal"
            inputProps={{ min: 0, step: 0.01 }}
          />
        </Grid>
      </Grid>
      <Button
        onClick={() => {
          saveEbaySettings(formState as IEbaySettings);
        }}
        variant="contained"
      >
        Save
      </Button>
    </Box>
  );
};

export default EbayFees;
