import {
  Box,
  Button,
  Grid2,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import { Check, ChevronLeft, ChevronRight } from '@mui/icons-material';
import useOrganisationAccounts from '../../hooks/useOrganisationAccounts';

const CreateOrganisationAccount = () => {
  const { palette } = useTheme();
  const options = [
    {
      id: 'new',
      text: 'Create a new account',
    },
    {
      id: 'existing',
      text: 'Join an existing account',
    },
  ];

  const [step, setStep] = React.useState<number>(0);

  const [selectedOption, setSelectedOption] = React.useState<string>('');

  const [userInput, setUserInput] = React.useState<string>('');

  const { createNewOrganisationAccount, joinExistingOrganisationAccount } =
    useOrganisationAccounts();

  const onEnd = () => {
    if (!userInput) alert('Please enter a name for your account');
    if (selectedOption === options?.[0]?.id) {
      console.log('Create new account');
      createNewOrganisationAccount(userInput);
      return;
    }
    if (selectedOption === options?.[1]?.id) {
      console.log('Join existing account');
    }
  };

  const onNext = () => {
    if (step < 1) {
      setStep(step + 1);
      return;
    }
    if (step === 1) {
      onEnd();
    }
  };

  const onBack = () => {
    setUserInput('');
    if (step > 0) setStep(step - 1);
  };
  return (
    <Box
      sx={{
        backgroundColor: palette?.background?.default,
        height: '100vh',
        overflowY: 'scroll',
        ...CENTER,
        flexDirection: 'column',
      }}
      p={3}
      borderRadius={3}
    >
      <Box sx={{ textAlign: 'center', width: 420 }}>
        {step === 0 && (
          <Grid2
            container
            spacing={2}
            sx={{
              mb: 2,
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {options?.map(({ id, text }) => {
              const selected = selectedOption === id;

              return (
                <Grid2
                  size={{ xs: 6 }}
                  key={id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 200,
                    height: 250,
                    border: '1px solid',
                    borderColor: selected
                      ? palette?.primary?.main
                      : palette?.divider,
                    borderRadius: 2,
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                  onClick={() => {
                    setSelectedOption(id);
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    {selected && (
                      <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                        <Check sx={{ fontSize: 20 }} />
                      </Box>
                    )}
                    {/* <Typography variant="h2">{flag}</Typography> */}
                    <Typography>{text}</Typography>
                  </Box>
                </Grid2>
              );
            })}
          </Grid2>
        )}
        {step === 1 && selectedOption === options?.[0]?.id && (
          <Box>
            <Typography variant="h6">Create a new account</Typography>
            <Typography variant="body2">
              Enter a name for your new account (you can change this later)
            </Typography>
            <TextField
              fullWidth
              label="Account Name"
              variant="outlined"
              sx={{ mb: 2, mt: 1 }}
              onChange={(e) => {
                console.log(e.target.value);
                setUserInput(e.target.value);
              }}
              value={userInput}
            />
          </Box>
        )}
        {step === 1 && selectedOption === options?.[1]?.id && (
          <Box>
            <Typography variant="h6">Join an existing account</Typography>
          </Box>
        )}
        <Button
          variant="contained"
          endIcon={<ChevronRight />}
          sx={{ width: '100%', height: 50 }}
          onClick={onNext}
        >
          {step === 1 ? 'Lets GO' : 'NEXT'}
        </Button>
        {step > 0 && (
          <Button
            startIcon={<ChevronLeft />}
            sx={{ width: '100%', height: 50, mt: 2 }}
            onClick={onBack}
          >
            BACK
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CreateOrganisationAccount;
