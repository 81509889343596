/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Button,
  colors,
  Grid2,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { CompareListState } from '../stateManagement/CompareListState';
import { truncate } from 'lodash';
import useCompareList from '../utils/hooks/useCompareList';
import {
  DeleteOutline,
  Edit,
  Expand,
  Fullscreen,
  Settings,
} from '@mui/icons-material';
import useCompareServices from '../utils/hooks/useCompareServices';
import { type AmazonProductType } from '../utils/TYPES';
import useLocalContent, {
  type LanguageContent,
} from '../utils/hooks/useLocalContent';
import { COMPARE_LIST_CONTENT } from '../contents/COMPARE_LIST_CONTENT';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import COLORS from '../utils/CONSTS/COLORS';
import { CENTER } from '../utils/CONSTS/PRESTYLES';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const CompareListComponent = ({
  openListModal,
  layout,
  setLayout,
}: {
  openListModal: () => void,
  layout: 'minimized' | 'full' | 'row',
  setLayout: (t: 'minimized' | 'full' | 'row') => void,
}) => {
  const { palette, shadows } = useTheme();
  const { activeCompareList, activeCompareListId } =
    useReactiveVar(CompareListState);

  const { CONTENT }: LanguageContent = useLocalContent(COMPARE_LIST_CONTENT);

  const {
    addRemoveProductsToCompareList,
    updateCompareListState,
    setCompareListAsActive,
  } = useCompareList({});

  const { compareServices } = useCompareServices();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const _compareService = async () => {
    setLoading(true);
    const compareId = await compareServices(
      `${activeCompareList?.name}-compare`,
      activeCompareList?.products as AmazonProductType[],
    );
    if (!compareId) return;
    await updateCompareListState({ compareId, active: false });
    setLoading(false);
    navigate('/compares');
    setCompareListAsActive(null);
  };

  if (!activeCompareListId) return null;
  if (layout === 'minimized') {
    return (
      <Box
        sx={{
          backgroundColor: palette?.customBackground?.dark,
          borderRadius: 2,
          boxShadow: shadows[1],
          padding: 2,
          mb: 2,
          position: 'fixed',
          bottom: 20,
          left: 20,
          right: 'auto',
          overflow: 'scroll',
          WebkitBoxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
          width: 200,
          borderColor: palette?.grey[300],
          borderStyle: 'solid',
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" color="primary" fontWeight="bold">
          {truncate(activeCompareList?.name, { length: 20 })} (
          {activeCompareList?.products?.length})
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            onClick={() => {
              setLayout('row');
            }}
            sx={{
              backgroundColor: '#f4bf4f',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderRadius: 3,
              width: 20,
              height: 20,
              cursor: 'pointer',
            }}
          >
            <Expand sx={{ fontSize: 15, color: COLORS.PRIMARY_DARK }} />
          </Box>
          <Box
            onClick={() => {
              setLayout('full');
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              ml: 1,
              backgroundColor: '#62c554',
              width: 20,
              height: 20,
              borderRadius: 3,
              cursor: 'pointer',
            }}
          >
            <OpenInFullIcon sx={{ fontSize: 15, color: COLORS.PRIMARY_DARK }} />
          </Box>
        </Box>
      </Box>
    );
  }

  if (layout === 'row') {
    return (
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          borderRadius: 2,
          boxShadow: shadows[1],
          mb: 2,
          position: 'fixed',
          bottom: 20,
          left: 'auto',
          right: 'auto',
          overflow: 'scroll',
          WebkitBoxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
          maxWidth: '80vw',
          minWidth: '80vw',
          borderColor: palette?.grey[300],
          borderStyle: 'solid',
          borderWidth: 1,
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
            position: 'fixed',
            width: '80vw',
            borderRadius: 2,
          }}
        >
          <Tooltip title={CONTENT?.TT_LIST_SETTING}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={openListModal}
            >
              <Typography variant="h6" color="primary" fontWeight="bold">
                {activeCompareList?.name} ({activeCompareList?.products?.length}
                )
              </Typography>
              <Box sx={{ ml: 2 }}>
                <Settings />
              </Box>
            </Box>
          </Tooltip>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ mr: 2 }}>
              <LoadingButton
                disabled={!activeCompareList?.products?.length}
                variant="contained"
                onClick={_compareService}
                loading={loading}
              >
                Start Compare
              </LoadingButton>
            </Box>
            <Box
              onClick={() => {
                setLayout('minimized');
              }}
              sx={{
                backgroundColor: '#f4bf4f',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: 3,
                width: 20,
                height: 20,
                cursor: 'pointer',
              }}
            >
              <Typography color={COLORS.PRIMARY_DARK} fontSize={30}>
                -
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setLayout('full');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                ml: 1,
                backgroundColor: '#62c554',
                width: 20,
                height: 20,
                borderRadius: 3,
                cursor: 'pointer',
              }}
            >
              <OpenInFullIcon
                sx={{ fontSize: 15, color: COLORS.PRIMARY_DARK }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: 3,
            mt: 6,
          }}
        >
          {!!activeCompareList?.products?.length &&
            activeCompareList?.products?.map((product) => (
              <Box
                key={product?.asin}
                sx={{
                  width: 150,
                  mr: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: palette?.surface?.dark,
                  pb: 1,
                  borderRadius: 1,
                }}
              >
                <Box
                  width={120}
                  height={100}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    backgroundColor: 'white',
                    ...CENTER,
                  }}
                  overflow={'hidden'}
                >
                  <img
                    src={product?.product_photo}
                    alt="product"
                    style={{
                      width: 80,
                      height: 'auto',
                    }}
                  />
                </Box>
                <Typography
                  textAlign={'center'}
                  fontSize={12}
                  padding={1}
                  minHeight={50}
                >
                  {truncate(product?.product_title, { length: 20 })}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    addRemoveProductsToCompareList([product]);
                  }}
                  color="error"
                >
                  <DeleteOutline sx={{ fontSize: 20 }} />
                </Button>
              </Box>
            ))}
        </Box>
      </Box>
    );
  }

  if (layout === 'full') {
    return (
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          borderRadius: 2,
          boxShadow: shadows[1],
          mb: 2,
          position: 'fixed',
          bottom: 20,
          left: 'auto',
          right: 'auto',
          overflow: 'scroll',
          WebkitBoxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
          minWidth: '80vw',
          maxHeight: '90vh',
          borderColor: palette?.grey[300],
          borderStyle: 'solid',
          borderWidth: 1,
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
            backgroundColor: palette?.background?.default,
          }}
        >
          <Tooltip title={CONTENT?.TT_LIST_SETTING}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={openListModal}
            >
              <Typography variant="h6" color="primary" fontWeight="bold">
                {activeCompareList?.name} ({activeCompareList?.products?.length}
                )
              </Typography>
              <Box sx={{ ml: 2 }}>
                <Settings />
              </Box>
            </Box>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ mr: 2 }}>
              <LoadingButton
                variant="contained"
                disabled={!activeCompareList?.products?.length}
                onClick={_compareService}
                loading={loading}
              >
                Start Compare
              </LoadingButton>
            </Box>
            <Box
              onClick={() => {
                setLayout('minimized');
              }}
              sx={{
                backgroundColor: '#f4bf4f',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: 3,
                width: 20,
                height: 20,
                cursor: 'pointer',
              }}
            >
              <Typography color={COLORS.PRIMARY_DARK} fontSize={30}>
                -
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setLayout('row');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                ml: 1,
                backgroundColor: '#62c554',
                width: 20,
                height: 20,
                borderRadius: 3,
                cursor: 'pointer',
              }}
            >
              <CloseFullscreenIcon
                sx={{ fontSize: 15, color: COLORS.PRIMARY_DARK }}
              />
            </Box>
          </Box>
        </Box>
        <Grid2
          gap={1}
          container
          mt={2}
          sx={{ justifyContent: 'center', pr: 3, pl: 3 }}
        >
          {!!activeCompareList?.products?.length &&
            activeCompareList?.products?.map((product) => (
              <Grid2
                key={product?.asin}
                size={{ xs: 12, sm: 6, md: 3, lg: 2 }}
                sx={{
                  backgroundColor: palette?.surface?.dark,
                  borderRadius: 2,
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                  paddingBottom: 2,
                  overflow: 'hidden',
                  mb: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}

                // onClick={() => getProductDetail(item?.asin)}
              >
                <Box
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    width: '100%',
                    aspectRatio: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <img
                    src={product?.product_photo}
                    alt="product"
                    style={{
                      width: 150,
                      height: 'auto',
                    }}
                  />
                </Box>
                <Typography padding={1}>
                  {truncate(product?.product_title, { length: 40 })}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    addRemoveProductsToCompareList([product]);
                  }}
                  color="error"
                >
                  <DeleteOutline />
                </Button>
              </Grid2>
            ))}
        </Grid2>
      </Box>
    );
  }
};

export default CompareListComponent;
