import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSyncManagedUsersProfile from '../../utils/hooks/useSyncManagedUsersProfile';
import { useUser } from '../../stateManagement/userStateManager';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { Backdrop, Box, Typography } from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import Loading from '../../components/Loading';
import COLORS from '../../utils/CONSTS/COLORS';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { useReactiveVar } from '@apollo/client';

const functions = getFunctions();
const AEAuthCode = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const { user, updateUser } = useUser();
  const selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );
  console.log('searchParams', code);
  const requestToken = async () => {
    try {
      console.log('requestToken called', code);

      const tokens = httpsCallableFromURL(
        functions,
        firebaseCall('requestAEToken'),
      );
      await tokens({
        code,
        refreshToken: null,
        currentOrganisationId: selectedOrganisationAccountState?.id,
      });
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.log('error Token', error);
    }
  };

  useEffect(() => {
    if (code && !!selectedOrganisationAccountState) {
      //  updateUser({ aEAuthCode: code });
      requestToken();
    }
  }, [selectedOrganisationAccountState?.id]);

  return (
    <Box sx={{ position: 'absolute', zIndex: 100, ...CENTER }}>
      <Backdrop open={true} sx={{ zIndex: 100 }}>
        <Box sx={{ ...CENTER, flexDirection: 'column' }}>
          <Typography sx={{ mb: 2 }}>
            Pleas Wait While Authenticating...
          </Typography>
          <l-helix
            size={50}
            stroke-length="0.25"
            bg-opacity="0.1"
            speed="0.9"
            color={COLORS?.PRIMARY}
          />
        </Box>
      </Backdrop>
    </Box>
  );
};

export default AEAuthCode;
