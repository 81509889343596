import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, TextField, useTheme } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

interface HTMLEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const HTMLEditor: React.FC<HTMLEditorProps> = ({ value, onChange }) => {
  const [tabIndex, setTabIndex] = useState(0); // 0 = Text, 1 = HTML
  const { palette } = useTheme();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handlePlainTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value); // Update both plain text and HTML
  };

  const handleHtmlChange = (html: string) => {
    onChange(html); // Update the plain text and HTML
  };

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="description-tabs"
      >
        <Tab label="Text Editor" />
        <Tab label="HTML Editor" />
      </Tabs>
      {tabIndex === 0 && (
        <Box mt={2} sx={{ backgroundColor: palette?.background?.default }}>
          <ReactQuill value={value} onChange={handleHtmlChange} theme="snow" />
        </Box>
      )}
      {tabIndex === 1 && (
        <Box mt={2}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={value}
            onChange={handlePlainTextChange}
            sx={{ backgroundColor: palette?.background?.default }}
          />
        </Box>
      )}
    </Box>
  );
};

export default HTMLEditor;
