import * as React from 'react';
import Box from '../../components/Box';
import AuthComponent from '../../components/AuthCompoenet';
import useAuth from '../../utils/hooks/useAuth';
import { SignInPage } from '@toolpad/core';
import { type AuthProvider } from 'firebase/auth';
import { Container, Grid2 } from '@mui/material';
import COLORS from '../../utils/CONSTS/COLORS';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';

const providers = [
  { id: 'github', name: 'GitHub' },
  { id: 'google', name: 'Google' },
  { id: 'facebook', name: 'Facebook' },
  { id: 'twitter', name: 'Twitter' },
  { id: 'linkedin', name: 'LinkedIn' },
];

const LoginPage = () => {
  const { login, loading } = useAuth();
  const signIn = async (provider: AuthProvider) => {
    const promise = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log(`Sign in with`);
        resolve();
      }, 500);
    });
    await promise;
  };
  return (
    <Grid2 container sx={{ width: '100vw', height: '100vh' }}>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <AuthComponent
          type="Login"
          loading={loading}
          onClick={(email, password) => {
            console.log('email', email);
            console.log('password', password);
            login({ email, password });
          }}
        />
      </Grid2>
      <Grid2
        size={{ xs: 0, md: 6 }}
        sx={{
          backgroundColor: COLORS.PRIMARY_DARK,
          ...CENTER,
          display: { xs: 'none', md: 'flex' },
        }}
      ></Grid2>
    </Grid2>
  );
};

export default LoginPage;
