import { type Theme } from '@mui/material';
import type React from 'react';
import { useCallback } from 'react';

type StyleType = Record<string, React.CSSProperties>;

const useStyles = () => {
  const Styles: (theme: Theme) => StyleType = useCallback(
    ({ palette }: Theme) => ({
      itemContainer: {
        borderRadius: 15,
        backgroundColor: palette?.surface?.dark,
        padding: 15,
        marginTop: 20,
      },
    }),
    [],
  );

  return Styles;
};

export default useStyles;
