/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import useAmazonAIBestSellerDiscoverer, {
  type AmazonAIBestSellerDiscovererType,
} from '../../../utils/hooks/useAmazonAIBestSellerDiscoverer';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { AIBestSellerProductCategoriesState } from '../../../stateManagement/AIBestSellerProductCategoriesState';
import { visuallyHidden } from '@mui/utils';
import { Add } from '@mui/icons-material';
import { capitalize, get } from 'lodash';
import COLORS from '../../../utils/CONSTS/COLORS';
import ProductSuggestionReasonModal from '../../../components/modals/ProductSuggestionReasonModal';
import StartCompareListModal from '../../../components/modals/StartCompareListModal';
import CompareListComponent from '../../../components/CompareListComponent';
import useCompareList from '../../../utils/hooks/useCompareList';
import useEbayDraftListing from '../../../utils/hooks/useEbayDraftListing';

interface HeadCell {
  disablePadding: boolean;
  id: keyof AmazonAIBestSellerDiscovererType;
  label: string;
  numeric: boolean;
}

type Order = 'asc' | 'desc';

const headCells: readonly HeadCell[] = [
  {
    id: 'product_photo',
    numeric: false,
    disablePadding: false,
    label: 'Image',
  },
  {
    id: 'product_title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Sub Category',
  },

  {
    id: 'product_star_rating',
    numeric: true,
    disablePadding: false,
    label: 'Stars',
  },
  {
    id: 'product_num_ratings',
    numeric: true,
    disablePadding: false,
    label: 'Reviews',
  },
  {
    id: 'score',
    numeric: true,
    disablePadding: false,
    label: 'Score',
  },
  {
    id: 'product_price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    label: 'Reason',
  },
  {
    id: 'asin',
    numeric: false,
    disablePadding: false,
    label: 'List on Ebay',
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: Order,
  orderBy: keyof AmazonAIBestSellerDiscovererType,
): (
  a: AmazonAIBestSellerDiscovererType,
  b: AmazonAIBestSellerDiscovererType,
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof AmazonAIBestSellerDiscovererType;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof AmazonAIBestSellerDiscovererType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  selectedCategory: string;
  numberOfProducts: number;
  addRemoveProductsToCompareList: (
    products: AmazonAIBestSellerDiscovererType[],
  ) => void;
  selected: AmazonAIBestSellerDiscovererType[];
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    selectedCategory,
    numberOfProducts,
    addRemoveProductsToCompareList,
    selected,
  } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Top Drop picks in {capitalize(selectedCategory)} ({numberOfProducts})
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Add">
          <IconButton
            onClick={() => {
              addRemoveProductsToCompareList(selected);
            }}
          >
            <Add />
            <Typography>Add to compare list</Typography>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

const AIProductSuggestions = () => {
  const { palette } = useTheme();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] =
    React.useState<keyof AmazonAIBestSellerDiscovererType>('score');
  const [selected, setSelected] = React.useState<
    // eslint-disable-next-line prettier/prettier
    AmazonAIBestSellerDiscovererType[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedProduct, setSelectedProduct] = useState<
    // eslint-disable-next-line prettier/prettier
    AmazonAIBestSellerDiscovererType | undefined
  >();

  console.log('selected', selected);

  const [layout, setLayout] = useState<'minimized' | 'full' | 'row'>('row');

  const [selectedCategory, setSelectedCategory] =
    React.useState<string>('garden');

  const { loading, getCategory, products } = useAmazonAIBestSellerDiscoverer();

  // const { createDraftFromAmazonTaskManager } = useEbayInventoryItem();
  const { createDraftFromAmazonTaskManager } = useEbayDraftListing();

  useEffect(() => {
    getCategory(selectedCategory);
  }, [selectedCategory]);

  const aIBestSellerProductCategoriesState = useReactiveVar(
    AIBestSellerProductCategoriesState,
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AmazonAIBestSellerDiscovererType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = products;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (product: AmazonAIBestSellerDiscovererType) => {
    const selectedIndex = selected.findIndex((p) => p?.asin === product?.asin);
    let newSelected: AmazonAIBestSellerDiscovererType[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, product);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...products]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, products],
  );

  const [isStartCompareListModalOpen, setIsStartCompareListModalOpen] =
    useState(false);
  const {
    createComparList,
    syncAllCompareLists,
    setCompareListAsActive,
    addRemoveProductsToCompareList,
    loading: compareListLoading,
  } = useCompareList({ setIsStartCompareListModalOpen });

  return (
    <Box>
      <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
        <Grid2 gap={1} container sx={{ pb: 2, pt: 4 }}>
          {!!aIBestSellerProductCategoriesState?.length &&
            aIBestSellerProductCategoriesState.map((category) => {
              const isSelected = selectedCategory === category.name;
              return (
                <Grid2 sx={{ xs: 12, sm: 6 }} key={category.name}>
                  <Box
                    sx={{
                      border: `2px solid ${palette?.surface?.dark}`,
                      backgroundColor: isSelected
                        ? palette?.primary?.main
                        : palette?.surface?.dark,
                      padding: 2,
                      pt: 1,
                      pb: 1,
                      borderRadius: 2,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      // getCategory(category.name);
                      setSelectedCategory(category.name);
                    }}
                  >
                    <Typography
                      color={isSelected ? COLORS?.WHITE : palette?.text.primary}
                    >
                      {capitalize(category.name ?? '')}
                    </Typography>
                  </Box>
                </Grid2>
              );
            })}
        </Grid2>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedCategory={selectedCategory}
          numberOfProducts={products?.length}
          addRemoveProductsToCompareList={addRemoveProductsToCompareList}
          selected={selected}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={products.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.asin}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => {
                        handleClick(row);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="normal"
                      sx={{ cursor: 'pointer' }}
                    >
                      <a
                        href={row.product_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={row.product_photo}
                          alt="product"
                          loading="lazy"
                          style={{
                            width: 500,
                            height: 'auto',
                          }}
                        />
                      </a>
                    </TableCell>
                    <TableCell align="left">{row.product_title}</TableCell>
                    <TableCell align="left">{row.category}</TableCell>
                    <TableCell align="right">
                      {row.product_star_rating}
                    </TableCell>
                    <TableCell align="right">
                      {row.product_num_ratings}
                    </TableCell>
                    <TableCell align="right">{row.score}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 700, fontSize: 16 }}
                    >
                      {row?.currency}
                      {row.product_price}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedProduct(row);
                      }}
                    >
                      <Button color="primary">reason</Button>
                    </TableCell>
                    <TableCell align="center" sx={{ cursor: 'pointer' }}>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => {
                          createDraftFromAmazonTaskManager(row?.asin);
                        }}
                      >
                        List
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        <ProductSuggestionReasonModal
          open={!!selectedProduct}
          onClose={() => {
            setSelectedProduct(undefined);
          }}
          product={selectedProduct}
        />
      </Box>
      <StartCompareListModal
        isOpen={isStartCompareListModalOpen}
        onClose={() => {
          setIsStartCompareListModalOpen(false);
        }}
        loading={compareListLoading}
        createComparList={createComparList}
        setCompareListAsActive={setCompareListAsActive}
      />
      <CompareListComponent
        openListModal={() => {
          setIsStartCompareListModalOpen(true);
        }}
        layout={layout}
        setLayout={setLayout}
      />
      {layout === 'row' && <Box height={370} width={400} />}
    </Box>
  );
};

export default AIProductSuggestions;
