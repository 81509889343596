/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import React, { useEffect, useRef } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './pages/Landing/LoginPage';
import RegisterPage from './pages/Landing/RegisterPage';
import './config/firebase';
import useIsLoggedIn from './utils/hooks/useIsLoggedin';
import SearchAmazonMarket from './pages/dashboard/SearchAmazonMarket';
import DashboardLayout from './layouts/DashboardLayout';
import Loading from './components/Loading';
import useSyncUser from './utils/hooks/useUser';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import useThemeProvider from './theme';
import LoggedInAppLayout from './layouts/LoggedInAppLayout';
import UserDetail from './pages/user/UserDetail';
import { useUser } from './stateManagement/userStateManager';

import ComparesLandingPage from './pages/compare/ComparesLandingPage';
import CompareDetailPage from './pages/compare/CompareDetailPage';
import ComparesFavoritePage from './pages/compare/ComparesFavoritePage';
import Subscription from './pages/subscription/Subscription';
import NicheLabLanding from './pages/nicheLab/NicheLabLanding';
import NicheLabSearchDetail from './pages/nicheLab/NicheLabSearchDetail';
import HomeScreen from './pages/homeScreen/HomeScreen';
import Box from './components/Box';
import AppLandingScreen from './pages/app/AppLandingScreen/AppLandingScreen';
import AIProductSuggestions from './pages/app/AIProductSuggestions/AIProductSuggestions';
import PrivacyPolicy from './pages/legalPages/privacyPolicy';
import TermsAndConditions from './pages/legalPages/TermsAndCondition';
import AEAuthCode from './pages/authCodes/AEAuthCode';
import EbayAuthCode from './pages/authCodes/EbayAuthCode';
import { useModalStateManager } from './stateManagement/ModalStateManager';
import OperationLoadingModal from './components/modals/OperationLoadingModal';
import EbayListingDetail from './pages/app/ebay/EbayListingDetail';
import EbayListingLandingPage from './pages/app/ebay/EbayListingLandingPage';
import { EbayInventoryLocationsState } from './stateManagement/EbayInventoryLocationsState';
import EbayLocationInventoryModal from './components/modals/EbayLocationInventoryModal';
import UserProfileLanding from './pages/userProfile/UserProfileLanding';
import EbayReturnPolicyModal from './components/modals/EbayReturnPolicyModal';
import EbayFulfillmentPolicyModal from './components/modals/EbayFulfillmentPolicyModal';
import EbayPaymentPolicyModal from './components/modals/EbayPaymentPolicyModal';
import EbayVariantEditorModal from './components/modals/EbayVariantEditorModal';
import SettingsLanding from './pages/settings/SettingsLanding';
import OneSignal from 'react-onesignal';
import { hasSubscribers } from 'diagnostics_channel';
import EbayConnectAccountModal from './components/modals/EbayConnectAccountModal';
import SetupEbayAccount from './pages/app/ebay/SetupEbayAccount';
import EbaySettingCompletedModal from './components/modals/EbaySettingCompletedModal';
import WarningModal from './components/modals/WarningModal';
import AddStoreModal from './components/modals/AddStoreModal';
import CreateOrganisationAccount from './pages/createOrganisationAccount/CreateOrganisationAccount';
import useSyncOrganisationAccounts from './utils/hooks/useSyncOrganisationAccounts';
import { useReactiveVar } from '@apollo/client';
import { OrganisationAccounts } from './stateManagement/OrganisationAccounts';
import VerifyEmail from './pages/verifyEmail';
import CreditSystem from './pages/info/CreditSystem';
import CreditSystemModal from './components/modals/CreditSystemModal';

function App(): JSX.Element | null {
  const isLoggedIn = useIsLoggedIn();

  const navigate = useNavigate();
  useSyncUser();

  const theme = useThemeProvider();

  const { permissionStatus, isEmailVerified } = useUser();

  console.log('permissionStatus', permissionStatus);

  const [modalState, updateModalState, resetModal] = useModalStateManager();

  const isInitialised = useRef(false);
  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined' && !isInitialised.current) {
      console.log('init onesignal');

      OneSignal.init({
        appId: process.env.REACT_APP_onesignal_app_id as string,
        // You can add other initialization options here
        notifyButton: {
          enable: true,
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        // allowLocalhostAsSecureOrigin: true,
      });
      isInitialised.current = true;
    }
  }, []);

  useEffect(() => {
    if (permissionStatus !== undefined && !permissionStatus?.hasAccess) {
      navigate('/');
    }
    OneSignal.User.addTag(
      'hasSubscription',
      String(!!permissionStatus?.hasAccess),
    );
    // if (isLoggedIn === false) navigate('/');
  }, [permissionStatus, isLoggedIn]);

  const hasSubscription = permissionStatus?.hasAccess;

  useSyncOrganisationAccounts();

  const organisationAccounts = useReactiveVar(OrganisationAccounts);

  const hasOrganisationAccount =
    organisationAccounts && organisationAccounts?.length > 0;

  console.log('hasOrganisationAccount', organisationAccounts);

  if (
    isLoggedIn === undefined ||
    (isLoggedIn === true && permissionStatus === undefined) ||
    organisationAccounts === undefined
  )
    return (
      <Box center flex width="100vw" height="100vh">
        <Loading size={100} />
      </Box>
    );
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          {isLoggedIn === undefined && (
            <Route path="/" element={<Loading size={100} />} />
          )}
          {isLoggedIn === false && (
            <Route>
              <Route index element={<HomeScreen />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="register" element={<RegisterPage />} />
            </Route>
          )}
          {isLoggedIn === true && !isEmailVerified && (
            <Route>
              <Route index Component={VerifyEmail} />
            </Route>
          )}
          {isLoggedIn === true &&
            !hasOrganisationAccount &&
            isEmailVerified && (
              <Route>
                <Route index element={<CreateOrganisationAccount />} />
              </Route>
            )}
          {isLoggedIn === true &&
            !hasSubscription &&
            hasOrganisationAccount &&
            isEmailVerified && (
              <Route path="/">
                <Route index Component={Subscription} />
              </Route>
            )}
          {isLoggedIn === true &&
            hasSubscription &&
            hasOrganisationAccount &&
            isEmailVerified && (
              <Route path="/" Component={LoggedInAppLayout}>
                <Route Component={DashboardLayout}>
                  <Route index Component={AppLandingScreen} />
                  <Route path="compares" Component={ComparesLandingPage} />
                  <Route path="subscription" Component={Subscription} />
                  <Route path="search-market" Component={SearchAmazonMarket} />

                  <Route
                    path="compares/favorites"
                    Component={ComparesFavoritePage}
                  />
                  <Route path="compare/:id" Component={CompareDetailPage} />
                  <Route path="user/:id" Component={UserDetail} />

                  <Route path="nichelab" Component={NicheLabLanding} />
                  <Route path="nichelab/:id" Component={NicheLabSearchDetail} />
                  <Route
                    path="ai-product-suggestions"
                    Component={AIProductSuggestions}
                  />
                  <Route
                    path="ebay-listings/:id"
                    Component={EbayListingDetail}
                  />
                  <Route
                    path="ebay-listings"
                    Component={EbayListingLandingPage}
                  />

                  <Route path="profile" Component={UserProfileLanding} />

                  <Route
                    path="*"
                    element={
                      <Box center flex width="100%" height="100%">
                        no page
                        <Box
                          onClick={() => {
                            navigate('/');
                          }}
                        >
                          Go back
                        </Box>
                      </Box>
                    }
                  />
                  <Route path="settings" Component={SettingsLanding} />
                </Route>
                <Route path="setup-ebay-account" Component={SetupEbayAccount} />
                <Route path="ae-auth" Component={AEAuthCode} />
                <Route path="ebay-auth" Component={EbayAuthCode} />
              </Route>
            )}

          <Route path="privacy-policy" Component={PrivacyPolicy} />
          <Route path="terms-and-conditions" Component={TermsAndConditions} />
        </Routes>
        {modalState?.type === 'OPERATION' && (
          <OperationLoadingModal
            visible={modalState?.type === 'OPERATION'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'INVENTORY_LOCATION' && (
          <EbayLocationInventoryModal
            visible={modalState?.type === 'INVENTORY_LOCATION'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'RETURN_POLICY' && (
          <EbayReturnPolicyModal
            visible={modalState?.type === 'RETURN_POLICY'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'FULFILLMENT_POLICY' && (
          <EbayFulfillmentPolicyModal
            visible={modalState?.type === 'FULFILLMENT_POLICY'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'PAYMENT_POLICY' && (
          <EbayPaymentPolicyModal
            visible={modalState?.type === 'PAYMENT_POLICY'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'EBAY_LISTING_VARIATION_PRICE' && (
          <EbayVariantEditorModal
            visible={modalState?.type === 'EBAY_LISTING_VARIATION_PRICE'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'EBAY_CONNECT_ACCOUNT' && (
          <EbayConnectAccountModal
            visible={modalState?.type === 'EBAY_CONNECT_ACCOUNT'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'EBAY_SETTING_COMPLETED' && (
          <EbaySettingCompletedModal
            visible={modalState?.type === 'EBAY_SETTING_COMPLETED'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'WARNING' && (
          <WarningModal
            visible={modalState?.type === 'WARNING'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'ADD_STORE' && (
          <AddStoreModal
            visible={modalState?.type === 'ADD_STORE'}
            modalState={modalState}
            onClose={() => {
              resetModal();
            }}
          />
        )}
        {modalState?.type === 'CREDIT_SYSTEM' && (
          <CreditSystemModal
            visible={modalState?.type === 'CREDIT_SYSTEM'}
            modalState={modalState}
            onClose={() => {
              resetModal();
            }}
          />
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
