import React, { useMemo } from 'react';
import { CompareListState } from '../../stateManagement/CompareListState';
import { type AmazonProductType } from '../TYPES';
import { useReactiveVar } from '@apollo/client';

interface useIsInCompareListProps {
  asin: string;
}

const useIsInCompareList = ({ asin }: useIsInCompareListProps) => {
  const compareListState = useReactiveVar(CompareListState);
  const isInCompareList = useMemo(() => {
    return !!compareListState.activeCompareList?.products?.some(
      (product: AmazonProductType) => product.asin === asin,
    );
  }, [asin, compareListState]);
  return isInCompareList;
};

export default useIsInCompareList;
