/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { type AmazonProductType } from '../utils/TYPES';
import { AE_TOKEN, EBAY_TOKEN } from './useTokenValidation';

export interface AuthTokenStateType {
  EBAY_AUTH_TOKEN: boolean | undefined;
  AE_TOKEN: boolean | undefined;
}

export const AuthTokenState = makeVar<AuthTokenStateType>({
  EBAY_AUTH_TOKEN: undefined,
  AE_TOKEN: undefined,
});
