/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { type OrganisationAccountType } from './OrganisationAccounts';
import { type Timestamp } from 'firebase/firestore';
import {
  type IOrganisationStore,
  type MarketPlaceCountryType,
} from '../utils/TYPES';

export const SelectedStoreState = makeVar<
  // eslint-disable-next-line prettier/prettier
  IOrganisationStore | undefined | null
>(undefined);
