/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { type AmazonProductType } from '../TYPES';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { useReactiveVar } from '@apollo/client';

interface useCompareServicesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products: any[];
}

const functions = getFunctions();
const database = getFirestore();
const auth = getAuth();

const useCompareServices = () => {
  const navigate = useNavigate();

  const selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );
  const compareServices = async (
    searchTerm: string,
    products: AmazonProductType[],
  ) => {
    try {
      console.log('comparing', products);

      const docRef = doc(
        database,
        `organisations/${selectedOrganisationAccountState?.id}`,
      ); // Reference to the user document
      const compareProductImagesRef = collection(
        docRef,
        'compareProductImages',
      ); // Reference to the subcollection

      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      const saveItem = await addDoc(compareProductImagesRef, {
        date: serverTimestamp(),
        'search term': searchTerm,
        'amazon products': products,
      });

      const search = httpsCallableFromURL(
        functions,
        firebaseCall('compareProductTaskManager'),
      );

      const response = await search({
        products,
        compareProductImagesId: saveItem?.id,
        region: ActiveMarketPlace(),
      });
      if (response?.data === 'expired') {
        window.open(
          'https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://dropcompare.com/ae-autorisation&client_id=511112',
          '_blank',
        );
      }
      return saveItem?.id;
      console.log('response amazonProductImageSearchAndSave', response);
    } catch (err) {
      console.error('Error comparing services', err);
    }
  };
  return { compareServices };
};

export default useCompareServices;
