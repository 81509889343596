const env = process.env.NODE_ENV;

const isProd = env === 'production';

export const isStipeTest = true;

export interface SubscriptionPlan {
  id: string;
  successUrl: string;
  cancelUrl: string;
  price: number;
  currency: string;
  interval: 'month' | 'year';
  name: string;
  description: string;
  features: string[];
}

export interface SubscriptionProducts {
  starter: {
    monthly: SubscriptionPlan,
    yearly: SubscriptionPlan,
  };
  pro: {
    monthly: SubscriptionPlan,
    yearly: SubscriptionPlan,
  };
}

export const SUBSCRIPTION_PRODUCTS: SubscriptionProducts = {
  starter: {
    monthly: {
      id: !isStipeTest ? '' : 'price_1QDbE5LBYDqfrJYhGuWG8IXc',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 19,
      currency: '$',
      interval: 'month',
      name: 'Starter',
      description: 'Starter subscription',
      features: [
        'Compare prices between 3 markets',
        'Auto-import products to eBay',
        'Access to niche finder',
        '15000 credits',
        'List up to 200 products on eBay',
      ],
    },
    yearly: {
      id: !isStipeTest ? '' : 'price_1QDruDLBYDqfrJYhK7RuRFkC',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 15.99,
      currency: '$',
      interval: 'month',
      name: 'Starter',
      description: 'Starter subscription',
      features: [
        'Compare prices between 3 markets',
        'Auto-import products to eBay',
        'Access to niche finder',
        '15000 credits',
        'List up to 200 products on eBay',
      ],
    },
  },
  pro: {
    monthly: {
      id: !isStipeTest ? '' : 'price_1QDrv1LBYDqfrJYhl0kG60Tl',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 35,
      currency: '$',
      interval: 'month',
      name: 'Pro',
      description: 'Pro subscription',
      features: [
        'Compare prices between 3 markets',
        'Auto-import products to eBay',
        'Access to niche finder',
        '25000 credits',
        'List up to 800 products on eBay',
        'Add team members',
      ],
    },
    yearly: {
      id: !isStipeTest ? '' : 'price_1QDrveLBYDqfrJYhU5dBJRW7',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 30,
      currency: '$',
      interval: 'month',
      name: 'Pro',
      description: 'Pro subscription',
      features: [
        'Compare prices between 3 markets',
        'Auto-import products to eBay',
        'Access to niche finder',
        '25000 credits',
        'List up to 800 products on eBay',
        'Add team members',
      ],
    },
  },
};
