/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import {
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import {
  Box,
  FormLabel,
  Grid2,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type IEbayInventoryLocation } from '../../stateManagement/EbayInventoryLocationsState';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../utils/hooks/useEbayInventoryItem';
import { type IEbayPolicy } from '../../stateManagement/EbayPolicyState';
import { G } from '@react-pdf/renderer';
import { ActiveEbayPlaceId } from '../../stateManagement/ActiveEbayPlaceId';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const EbayFulfillmentPolicyModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();

  const isUS = modalState?.data?.marketplaceId === 'EBAY_US';

  console.log('modalState isUS', isUS);

  const initState: IEbayPolicy['fulfillmentPolicies'][0] = {
    categoryTypes: [{ name: 'ALL_EXCLUDING_MOTORS_VEHICLES', default: false }],
    handlingTime: {
      unit: 'DAY',
      value: 7,
    },
    globalShipping: false,
    name: '',
    marketplaceId: ActiveEbayPlaceId() as string,
    shippingOptions: [
      {
        costType: 'FLAT_RATE',
        optionType: 'DOMESTIC',
        shippingServices: [
          {
            buyerResponsibleForPickup: false,
            buyerResponsibleForShipping: false,
            freeShipping: true,
            shippingCost: {
              currency: isUS ? 'USD' : 'GBP',
              value: 0,
            },
            shippingCarrierCode: 'GENERIC',
            shippingServiceCode: isUS
              ? 'ShippingMethodStandard'
              : 'UK_OtherCourier',
          },
        ],
      },
    ],
  };

  const [formState, setFormState] = React.useState(initState);
  const { createFulfillmentPolicy } = useEbayInventoryItem();

  const handleChange = (e: any, path: Array<string | number>) => {
    const value = e.target.value;

    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: any) => {
      const updatedState = { ...prevState };

      let current: any = updatedState;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value; // Always update the value correctly

      return updatedState; // Return the updated state
    });
  };

  console.log('formState', formState);

  // is all filled all required fields should be filled
  const isReady = useMemo(() => {
    const {
      name,
      marketplaceId,
      handlingTime: { unit, value } = { unit: '', value: 0 },
      globalShipping,
      shippingOptions: [{ costType, optionType, shippingServices }] = [
        {
          costType: '',
          optionType: '',
          shippingServices: [
            {
              buyerResponsibleForPickup: false,
              buyerResponsibleForShipping: false,
              freeShipping: true,
              shippingCost: {
                currency: 'USD',
                value: '0.0',
              },
              shippingCarrierCode: 'GENERIC',
              shippingServiceCode: 'ShippingMethodStandard',
            },
          ],
        },
      ],
      categoryTypes,
    } = formState;
    return name && marketplaceId && unit && value;
  }, [formState]);

  const {
    name,
    marketplaceId,
    handlingTime: { unit, value } = { unit: '', value: '0.0' },
  } = formState;

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <EbayLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          Create Fulfillment Policy
        </Typography>
        <Box sx={{ ...CENTER, flexDirection: 'column' }}>
          <Grid2 container maxWidth={'70%'} spacing={2}>
            <Grid2 size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={(e) => {
                  handleChange(e, ['name']);
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Select
                fullWidth
                label="Marketplace"
                value={marketplaceId}
                onChange={(e) => {
                  handleChange(e, ['marketplaceId']);
                }}
              >
                <MenuItem value="EBAY_US">EBAY_US</MenuItem>
                <MenuItem value="EBAY_GB">EBAY_GB</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Handling Time Value</FormLabel>
              <TextField
                type="number"
                fullWidth
                value={value}
                onChange={(e) => {
                  handleChange(e, ['handlingTime', 'value']);
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Handling Time Unit</FormLabel>
              <Select
                fullWidth
                value={unit}
                onChange={(e) => {
                  handleChange(e, ['handlingTime', 'unit']);
                }}
              >
                <MenuItem value="DAY">Day</MenuItem>
              </Select>
            </Grid2>
            {formState?.marketplaceId === 'EBAY_GB' && (
              <Grid2 size={{ xs: 12 }}>
                <FormLabel required>Global Shipping</FormLabel>
                <Select
                  fullWidth
                  value={formState?.globalShipping}
                  onChange={(e) => {
                    handleChange(
                      { target: { value: e?.target?.value === 'true' } },
                      ['globalShipping'],
                    );
                  }}
                >
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </Select>
              </Grid2>
            )}
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Cost Type</FormLabel>
              <Select
                fullWidth
                value={formState?.shippingOptions[0]?.costType}
                onChange={(e) => {
                  handleChange(e, ['shippingOptions', 0, 'costType']);
                }}
              >
                <MenuItem value="FLAT_RATE">Flat Rate</MenuItem>
                <MenuItem value="CALCULATED">Calculated</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Option Type</FormLabel>
              <Select
                fullWidth
                value={formState?.shippingOptions[0]?.optionType}
                onChange={(e) => {
                  handleChange(e, ['shippingOptions', 0, 'optionType']);
                }}
              >
                <MenuItem value="DOMESTIC">Domestic</MenuItem>
                <MenuItem value="INTERNATIONAL">International</MenuItem>
              </Select>
            </Grid2>
            {/* <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Shipping Service Code</FormLabel>
              <Select
                fullWidth
                value={
                  formState?.shippingOptions[0]?.shippingServices[0]
                    ?.shippingServiceCode
                }
                onChange={(e) => {
                  handleChange(e, [
                    'shippingOptions',
                    0,
                    'shippingServices',
                    0,
                    'shippingServiceCode',
                  ]);
                }}
              >
                <MenuItem value="ShippingMethodStandard">
                  ShippingMethodStandard
                </MenuItem>
              </Select>
            </Grid2> */}
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Shipping Carrier Code</FormLabel>
              <Select
                fullWidth
                value={
                  formState?.shippingOptions[0]?.shippingServices[0]
                    ?.shippingCarrierCode
                }
                onChange={(e) => {
                  handleChange(e, [
                    'shippingOptions',
                    0,
                    'shippingServices',
                    0,
                    'shippingCarrierCode',
                  ]);
                }}
              >
                <MenuItem value="GENERIC">Generic</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Shipping Service Code</FormLabel>
              <Select
                fullWidth
                value={
                  formState?.shippingOptions[0]?.shippingServices[0]
                    ?.shippingServiceCode
                }
                onChange={(e) => {
                  handleChange(e, [
                    'shippingOptions',
                    0,
                    'shippingServices',
                    0,
                    'shippingServiceCode',
                  ]);
                }}
              >
                {formState?.marketplaceId === 'EBAY_US' ? (
                  <MenuItem value="ShippingMethodStandard">
                    ShippingMethodStandard
                  </MenuItem>
                ) : (
                  <MenuItem value="UK_OtherCourier">UK_OtherCourier</MenuItem>
                )}
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>Free Shipping</FormLabel>
              <Select
                fullWidth
                value={
                  formState?.shippingOptions[0]?.shippingServices[0]
                    ?.freeShipping
                }
                onChange={(e) => {
                  handleChange(
                    { target: { value: e?.target?.value === 'true' } },
                    [
                      'shippingOptions',
                      0,
                      'shippingServices',
                      0,
                      'freeShipping',
                    ],
                  );
                }}
              >
                <MenuItem value={'true'}>Yes</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Shipping Cost</FormLabel>
              <TextField
                type="text"
                fullWidth
                value={
                  formState?.shippingOptions[0]?.shippingServices[0]
                    ?.shippingCost?.value
                }
                onChange={(e) => {
                  handleChange(e, [
                    'shippingOptions',
                    0,
                    'shippingServices',
                    0,
                    'shippingCost',
                    'value',
                  ]);
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Shipping Cost Currency</FormLabel>
              <Select
                fullWidth
                value={
                  formState?.shippingOptions[0]?.shippingServices[0]
                    ?.shippingCost?.currency
                }
                onChange={(e) => {
                  handleChange(e, [
                    'shippingOptions',
                    0,
                    'shippingServices',
                    0,
                    'shippingCost',
                    'currency',
                  ]);
                }}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
              </Select>
            </Grid2>
          </Grid2>
          <LoadingButton
            loading={modalState?.loading}
            disabled={!isReady}
            startIcon={<Add />}
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => {
              createFulfillmentPolicy(formState);
            }}
          >
            Create Return Policy
          </LoadingButton>
          {modalState?.error && (
            <Typography mt={2} color={'error'}>
              {modalState?.error}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EbayFulfillmentPolicyModal;
