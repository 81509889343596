/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import {
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import {
  Box,
  FormLabel,
  Grid2,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type IEbayInventoryLocation } from '../../stateManagement/EbayInventoryLocationsState';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../utils/hooks/useEbayInventoryItem';
import { type IEbayPolicy } from '../../stateManagement/EbayPolicyState';
import { ActiveEbayPlaceId } from '../../stateManagement/ActiveEbayPlaceId';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const EbayReturnPolicyModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();
  const initState: IEbayPolicy['returnPolicies'][0] = {
    categoryTypes: [{ name: 'ALL_EXCLUDING_MOTORS_VEHICLES', default: false }],
    name: '',
    returnsAccepted: true,
    marketplaceId: ActiveEbayPlaceId() as string,
    refundMethod: 'MONEY_BACK',
    returnMethod: 'MONEY_BACK',
    returnPeriod: {
      unit: 'DAY',
      value: 30,
    },
    returnShippingCostPayer: 'BUYER',
  };

  console.log('ActiveEbayPlaceId()', ActiveEbayPlaceId());

  const [formState, setFormState] = React.useState(initState);
  const [merchantLocationKey, setMerchantLocationKey] = React.useState('');

  const { createReturnPolicy } = useEbayInventoryItem();

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;

    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: any) => {
      const updatedState = { ...prevState };

      let current: any = updatedState;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value; // Always update the value correctly

      return updatedState; // Return the updated state
    });
  };

  console.log('formState', formState);

  // is all filled all required fields should be filled
  const isReady = useMemo(() => {
    const {
      name,
      returnsAccepted,
      marketplaceId,
      refundMethod,
      returnMethod,
      returnPeriod: { unit, value } = { unit: '', value: 0 },
      returnShippingCostPayer,
    } = formState;
    return (
      name &&
      marketplaceId &&
      refundMethod &&
      unit &&
      value &&
      returnShippingCostPayer
    );
  }, [formState]);

  const {
    name,
    returnsAccepted,
    marketplaceId,
    refundMethod,
    returnMethod,
    returnPeriod,
    returnShippingCostPayer,
  } = formState;

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <EbayLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          Create Return Policy
        </Typography>
        <Box sx={{ ...CENTER, flexDirection: 'column' }}>
          <Grid2 container maxWidth={'70%'} spacing={2}>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>Name</FormLabel>
              <TextField
                variant="outlined"
                value={name}
                onChange={(e) => {
                  handleChange(e, ['name']);
                }}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Marketplace</FormLabel>
              <Select
                value={marketplaceId}
                onChange={(e) => {
                  handleChange(e, ['marketplaceId']);
                }}
                fullWidth
                disabled
              >
                <MenuItem value={'EBAY_US'}>EBAY US (United State)</MenuItem>
                <MenuItem value={'EBAY_GB'}>EBAY GB (United Kingdom)</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Return Accepted</FormLabel>
              <Select
                value={`${returnsAccepted}`}
                onChange={(e) => {
                  handleChange(
                    { target: { value: e?.target?.value === 'true' } },
                    ['returnsAccepted'],
                  );
                }}
                fullWidth
              >
                <MenuItem value={'true'}>Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </Grid2>
            {formState?.returnsAccepted && (
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 6 }}>
                  <FormLabel required>Refund Method</FormLabel>
                  <Select
                    value={refundMethod}
                    onChange={(e) => {
                      handleChange(e, ['refundMethod']);
                    }}
                    fullWidth
                  >
                    <MenuItem value={'MONEY_BACK'}>Money Back</MenuItem>
                    <MenuItem value={'EXCHANGE'}>Exchange</MenuItem>
                  </Select>
                </Grid2>
                {/* <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Return Method</FormLabel>
              <Select
                value={returnMethod}
                onChange={(e) => {
                  handleChange(e, ['returnMethod']);
                }}
                fullWidth
              >
                <MenuItem value={'REFUND'}>Refund</MenuItem>
                <MenuItem value={'EXCHANGE'}>Exchange</MenuItem>
                <MenuItem value={'REPLACEMENT'}>Replacement</MenuItem>
              </Select>
            </Grid2> */}
                <Grid2 size={{ xs: 6 }}>
                  <FormLabel required>Return Period</FormLabel>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={returnPeriod?.value}
                    onChange={(e) => {
                      handleChange(e, ['returnPeriod', 'value']);
                    }}
                    fullWidth
                  />
                </Grid2>
                <Grid2 size={{ xs: 6 }}>
                  <FormLabel required>Return Period Unit</FormLabel>
                  <Select
                    value={returnPeriod?.unit}
                    onChange={(e) => {
                      handleChange(e, ['returnPeriod', 'unit']);
                    }}
                    fullWidth
                  >
                    <MenuItem value={'DAY'}>Day</MenuItem>
                    <MenuItem value={'MONTH'}>Month</MenuItem>
                    <MenuItem value={'BUSINESS_DAY'}>Business Day</MenuItem>
                  </Select>
                </Grid2>
                <Grid2 size={{ xs: 6 }}>
                  <FormLabel required>Return Shipping Cost Payer</FormLabel>
                  <Select
                    value={returnShippingCostPayer}
                    onChange={(e) => {
                      handleChange(e, ['returnShippingCostPayer']);
                    }}
                    fullWidth
                  >
                    <MenuItem value={'BUYER'}>Buyer</MenuItem>
                    <MenuItem value={'SELLER'}>Seller</MenuItem>
                  </Select>
                </Grid2>
              </Grid2>
            )}
          </Grid2>
          <LoadingButton
            loading={modalState?.loading}
            disabled={!isReady}
            startIcon={<Add />}
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => {
              const createItem = formState?.returnsAccepted
                ? formState
                : {
                    categoryTypes: formState?.categoryTypes,
                    marketplaceId: formState?.marketplaceId,
                    name: formState?.name,
                    returnsAccepted: false,
                  };
              createReturnPolicy(createItem);
            }}
          >
            Create Return Policy
          </LoadingButton>
          {modalState?.error && (
            <Typography mt={2} color={'error'}>
              {modalState?.error}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EbayReturnPolicyModal;
