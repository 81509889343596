/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid2,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '../../components/Box';
import Loading from '../../components/Loading';
import SPACES from '../../utils/CONSTS/SPACES';
import { useUser } from '../../stateManagement/userStateManager';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import useAmazon from '../../utils/hooks/useAmazon';
import { LoadingButton } from '@mui/lab';
import { type AmazonProductType } from '../../utils/TYPES';
import useCompareToEbay from '../../utils/hooks/useCompareToEbay';
import LinearProgressWithLabel from '../../components/LinearProgress';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useCompareServices from '../../utils/hooks/useCompareServices';
import useGetComparedRecords from '../../utils/hooks/useGetComparedRecords';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { flatten, set, truncate, values } from 'lodash';
import ProductCard from '../../components/ProductCard';
import ProductCardTemplate from '../../components/ProductCardTemplate';
import { useReactiveVar } from '@apollo/client';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import createAffiliateLink from '../../utils/helpers/createAffiliateLink';
import CopyText from '../../components/CopyText';
import COLORS from '../../utils/CONSTS/COLORS';
import AmazonLogo from '../../components/AmazonLogo';
import AliExpressLogo from '../../components/AliExpressLogo';
import EbayLogo from '../../components/EbayLogo';
import useAEAPI from '../../utils/hooks/useAEAPI';
import useEbayInventoryItem from '../../utils/hooks/useEbayInventoryItem';
import useEbayDraftListing from '../../utils/hooks/useEbayDraftListing';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

const db = getFirestore();
const auth = getAuth();

const CompareDetailPage = (): JSX.Element | null => {
  const { id: compareDocId } = useParams();
  const navigate = useNavigate();

  const { palette } = useTheme();

  const region = useReactiveVar(ActiveMarketPlace);
  const isUS = region === 'US';

  console.log('compareDocId:  ', compareDocId);

  const [productsToDisplay, setProductsToDisplay] = useState<any[]>([]);
  const [expandRow, setExpandRow] = useState<any>({});

  const { getAEProductByProductId } = useAEAPI('US');

  const { createDraftInventoryItemForEbay } = useEbayInventoryItem();

  const { createDraftFromAmazonTaskManager, createDraftFromAETaskManager } =
    useEbayDraftListing();

  const getFireStoreData = async () => {
    try {
      const response = await getDocs(
        collection(
          db,
          `organisations/${SelectedOrganisationAccountState()?.id}/compareProductImages/${compareDocId}/results`,
        ),
      );
      const data = response.docs.map((_doc) => ({
        id: _doc?.id,
        ..._doc.data(),
      }));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setProductsToDisplay(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getFireStoreData();
  }, []);

  if (!productsToDisplay?.length)
    return (
      <Box center flex height={'100vh'} width={'100vw'}>
        <Loading size={160} />
      </Box>
    );

  return (
    <Container>
      <Box pt={SPACES.SPACE_L}>
        <Box row spaceBetween>
          <Typography variant="h6">
            {productsToDisplay?.length} products
          </Typography>
        </Box>
      </Box>
      {!!productsToDisplay?.length &&
        productsToDisplay.map((item: AmazonProductType, index) => {
          const sectionDisplayNumber = expandRow[item?.asin] ? 10 : 5;

          return (
            <Box key={item?.asin} mt={40} width={'100%'}>
              <Box
                mt={SPACES.SPACE_S}
                width={'100%'}
                row
                backgroundColor={palette?.surface?.dark}
                borderRadius={10}
              >
                <Box
                  flex
                  center
                  backgroundColor={'white'}
                  pr={10}
                  pl={10}
                  width={200}
                  height={200}
                  borderRadius={10}
                  overflow="hidden"
                >
                  <a
                    href={createAffiliateLink(item?.asin)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={item.product_photo}
                      alt="product"
                      loading="lazy"
                      style={{
                        width: 'auto',
                        height: '100%',
                        borderRadius: 10,
                      }}
                    />
                  </a>
                </Box>
                <Box
                  pl={15}
                  pr={15}
                  pt={15}
                  flex
                  style={{
                    flexDirection: 'column',
                    justifySelf: 'flex-start',
                    alignSelf: 'flex-start',
                  }}
                >
                  <Box
                    mb={2}
                    center
                    flex
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                  >
                    <AmazonLogo size={70} />
                  </Box>
                  <CopyText
                    text={item.product_title}
                    concatLength={70}
                    color={palette?.primary?.main}
                  />
                  <Typography mt={2} sx={{ opacity: 0.7 }}>
                    {item?.sales_volume}
                  </Typography>
                  {/* <Typography mt={2} sx={{ opacity: 0.7 }}>
            {item?.itemSoldLastMonth}
          </Typography> */}

                  <Typography sx={{ opacity: 0.7 }}>
                    {item?.delivery}
                  </Typography>
                  <Box>
                    <Typography variant="h6" mb={1}>
                      {item?.product_price} {item?.currency}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        createDraftFromAmazonTaskManager(item?.asin);
                      }}
                    >
                      Create Ebay Draft
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Grid2 container>
                <Grid2 size={6}>
                  {/* <AliExpressLogo size={50} /> */}
                  {!!item?.AEproducts &&
                    item?.AEproducts?.slice(0, sectionDisplayNumber).map(
                      (product: any) => {
                        return (
                          <ProductCardTemplate
                            key={product?.product_id}
                            id={product?.product_id}
                            productTitle={product?.product_title}
                            currency={product?.target_original_price_currency}
                            productPrice={product?.target_original_price}
                            productImage={product?.product_main_image_url}
                            productLink={product?.product_detail_url}
                            createDraftFromAETaskManager={
                              createDraftFromAETaskManager
                            }
                            isAEProduct={true}
                            hasActionButton={true}
                          />
                        );
                      },
                    )}
                </Grid2>
                <Grid2 size={6}>
                  {/* <EbayLogo size={50} /> */}
                  {!!item?.ebayProducts &&
                    item?.ebayProducts
                      ?.slice(0, sectionDisplayNumber)
                      .map((product: any) => {
                        return (
                          <ProductCardTemplate
                            key={product?.itemId}
                            id={product?.itemId}
                            productTitle={product?.title}
                            currency={
                              !isUS
                                ? product?.price?.convertedFromCurrency
                                : product?.price?.currency
                            }
                            productPrice={
                              !isUS
                                ? product?.price?.convertedFromValue
                                : product?.price?.value
                            }
                            productImage={product?.image?.imageUrl}
                            productLink={product?.itemWebUrl}
                          />
                        );
                      })}
                </Grid2>
              </Grid2>
              <Button
                variant="contained"
                sx={{ width: '100%', mt: 2 }}
                onClick={() => {
                  setExpandRow((prev: any) => {
                    return {
                      ...prev,
                      [item?.asin]: !prev[item?.asin],
                    };
                  });
                }}
              >
                Show More ...
              </Button>
            </Box>
          );
        })}
    </Container>
  );
};

export default CompareDetailPage;
