import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type ModalStateType } from '../../stateManagement/ModalStateManager';
import { ebayAuthUrl } from './TokenSateComponent';

interface EbaySettingCompletedModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const EbaySettingCompletedModal = ({
  visible,
  onClose,
  modalState,
}: EbaySettingCompletedModalProps) => {
  const { palette } = useTheme();
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <EbayLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          Ebay setup is completed
        </Typography>
        <Typography variant="body1" fontWeight={'400'} mb={2}>
          Congrats your setup is completed now you can start listing and benefit
          from all the app has to offer.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          Got it
        </Button>
      </Box>
    </Modal>
  );
};

export default EbaySettingCompletedModal;
