import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const benefits = [
  'Find the best product prices across Amazon, eBay, and AliExpress to maximize profit margins.',
  'Save time with automated product imports directly to eBay.',
  'Discover trending and high-demand niches with the built-in niche finder.',
  'Optimize your product listings with refined titles and descriptions for better sales.',
  'Collaborate with your team (Pro plan) to scale your dropshipping business efficiently.',
];

const BenefitsList: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        maxWidth: 600,
        textAlign: 'left',
        py: 3,
        flex: 1,
      }}
    >
      <Typography
        variant="h5"
        fontWeight="bold"
        gutterBottom
        textAlign="center"
        color="white"
      >
        Why Choose DropCompare?
      </Typography>
      <Typography variant="body1" textAlign="center" mb={3} color="white">
        Start your dropshipping journey with the best tools to save time, boost
        profits, and stay ahead of the competition.
      </Typography>
      <List>
        {benefits.map((benefit, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={benefit} sx={{ color: 'white' }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default BenefitsList;
