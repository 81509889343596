import { type IEbaySettings } from '../TYPES';

const EBAY_SETTING_INITIAL: IEbaySettings = {
  fees: {
    fixedFees: 0.3,
    platformFeePercentage: 13,
    profitPercentage: 50,
    roundCentTo99: true,
  },
  policies: {
    paymentPolicyId: '',
    returnPolicyId: '',
    fulfillmentPolicyId: '',
  },
  location: {
    merchantLocationKey: '',
  },
};

export default EBAY_SETTING_INITIAL;
