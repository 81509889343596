/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ModalStateManager } from '../../../stateManagement/ModalStateManager';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../../utils/hooks/useEbayInventoryItem';
import { useReactiveVar } from '@apollo/client';
import { EbayPolicyState } from '../../../stateManagement/EbayPolicyState';
import { EbayInventoryLocationsState } from '../../../stateManagement/EbayInventoryLocationsState';
import { type IEbaySettings } from '../../../utils/TYPES';
import useEbaySettings from '../../../utils/hooks/useEbaySettings';
import { cloneDeep } from 'lodash';
import { ActiveEbayPlaceId } from '../../../stateManagement/ActiveEbayPlaceId';

const EbayPolicies = () => {
  const { palette } = useTheme();
  const {
    listInventoryItemOnEbay,
    createInventoryLocation,
    createReturnPolicy,
    getReturnPolicy,
    getFulfillmentPolicy,
    getPaymentPolicy,
    createFulfillmentPolicy,
    createPaymentPolicy,
    getInventoryLocations,
  } = useEbayInventoryItem();

  const marketPlaceId = useReactiveVar(ActiveEbayPlaceId);

  useEffect(() => {
    if (!marketPlaceId) return;
    getFulfillmentPolicy(marketPlaceId);
    getReturnPolicy(marketPlaceId);
    getPaymentPolicy(marketPlaceId);
  }, [marketPlaceId]);

  const { ebaySettings, saveEbaySettings } = useEbaySettings();

  useEffect(() => {
    setFormState(cloneDeep(ebaySettings));
  }, [ebaySettings]);

  const { fulfillmentPolicies, returnPolicies, paymentPolicies } =
    useReactiveVar(EbayPolicyState);

  const [formState, setFormState] = React.useState<
    // eslint-disable-next-line prettier/prettier
    IEbaySettings | null | undefined
  >({
    policies: {
      returnPolicyId: '',
      fulfillmentPolicyId: '',
      paymentPolicyId: '',
    },
  });
  console.log('formState', formState);

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;
    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: IEbaySettings | null | undefined) => {
      const updatedState = { ...prevState };
      let current: Record<string, any> = updatedState;

      for (let i = 0; i < path.length - 1; i++) {
        const key = path[i];

        // Check if the key exists, if not, create an empty object
        if (!current[key]) {
          current[key] = {};
        }

        current = current[key];
      }

      // Set the final value
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value;

      return updatedState; // Return the updated state
    });
  };

  return (
    <Box>
      <Typography
        variant="h6"
        gutterBottom
        mt={4}
        sx={{ textDecoration: 'underline', mb: 2 }}
      >
        BUSINESS POLICIES
      </Typography>
      <Grid container mt={2} ml={2}>
        <Grid item xs={12}>
          <FormLabel required>Return Policy</FormLabel>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={10}>
              <Select
                value={formState?.policies?.returnPolicyId}
                onChange={(e: SelectChangeEvent) => {
                  handleChange(e, ['policies', 'returnPolicyId']);
                }}
                sx={{
                  backgroundColor: palette?.background?.default,
                }}
                fullWidth
              >
                {!!returnPolicies?.length &&
                  returnPolicies.map((policy) => {
                    return (
                      <MenuItem
                        key={policy?.returnPolicyId}
                        value={policy?.returnPolicyId}
                      >
                        {policy?.name} (
                        {policy?.returnsAccepted && policy?.returnMethod}
                        {', '}
                        {policy?.returnsAccepted &&
                          policy?.returnPeriod?.value}{' '}
                        {policy?.returnsAccepted && policy?.returnPeriod?.unit})
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  ModalStateManager({
                    type: 'RETURN_POLICY',
                  });
                }}
                startIcon={<Add />}
              >
                Return Policy
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2}>
          <FormLabel required>Fulfillment Policy</FormLabel>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={10}>
              <Select
                value={formState?.policies?.fulfillmentPolicyId}
                onChange={(e: SelectChangeEvent) => {
                  // createFulfillmentPolicy(e.target.value as string);
                  handleChange(e, ['policies', 'fulfillmentPolicyId']);
                }}
                sx={{
                  backgroundColor: palette?.background?.default,
                }}
                fullWidth
              >
                {!!fulfillmentPolicies?.length &&
                  fulfillmentPolicies.map((policy) => (
                    <MenuItem
                      key={policy?.fulfillmentPolicyId}
                      value={policy?.fulfillmentPolicyId}
                    >
                      {policy?.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  ModalStateManager({
                    type: 'FULFILLMENT_POLICY',
                    data: { marketplaceId: marketPlaceId },
                  });
                }}
                startIcon={<Add />}
              >
                Fulfillment Policy
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <FormLabel required>Payment Policy</FormLabel>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={10}>
              <Select
                value={formState?.policies?.paymentPolicyId}
                onChange={(e: SelectChangeEvent) => {
                  handleChange(e, ['policies', 'paymentPolicyId']);
                }}
                sx={{
                  backgroundColor: palette?.background?.default,
                }}
                fullWidth
              >
                {!!paymentPolicies?.length &&
                  paymentPolicies.map((policy) => (
                    <MenuItem
                      key={policy?.paymentPolicyId}
                      value={policy?.paymentPolicyId}
                    >
                      {policy?.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  ModalStateManager({
                    type: 'PAYMENT_POLICY',
                  });
                }}
                startIcon={<Add />}
              >
                Payment Policy
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Button
          onClick={() => {
            saveEbaySettings(formState as IEbaySettings);
          }}
          variant="contained"
        >
          Save
        </Button>

        {/* <Grid item xs={12} mt={2}>
          <FormLabel required>Merchant Location</FormLabel>
          <Grid container sx={{ alignItems: 'center' }} spacing={2}>
            <Grid item xs={10}>
              <Select
                value={formState?.location?.merchantLocationKey}
                onChange={(e) => {
                  handleChange(e, ['location', 'merchantLocationKey']);
                }}
                fullWidth
                sx={{
                  backgroundColor: palette?.background?.default,
                }}
                required
              >
                {!!ebayInventoryLocations?.length &&
                  ebayInventoryLocations.map((location) => (
                    <MenuItem
                      key={location?.merchantLocationKey}
                      value={location?.merchantLocationKey}
                    >
                      {location?.merchantLocationKey} (
                      {location?.location?.address?.city}
                      {' - '}
                      {location?.location?.address?.country}
                      {' - '}
                      {location?.location?.address?.postalCode})
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  ModalStateManager({
                    type: 'INVENTORY_LOCATION',
                  });
                }}
                startIcon={<Add />}
              >
                Add Location
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default EbayPolicies;
