import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import {
  EbaySellerProfileInfoState,
  type IEbaySellerProfileInfoDataType,
} from '../../stateManagement/EbaySellerProfileInfo';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import { log } from 'node:console';

const functions = getFunctions();

const useSyncEbaySellerProfileInfo = () => {
  const syncSellerProfileInfo = async () => {
    try {
      EbaySellerProfileInfoState({
        loading: true,
      });
      const sellerProfile = httpsCallableFromURL(
        functions,
        firebaseCall('getSellerEbayProfileInfo'),
      );
      const response = await sellerProfile();

      EbaySellerProfileInfoState({
        loading: false,
        data: response.data as IEbaySellerProfileInfoDataType,
      });
    } catch (error) {
      console.error('Error getting ebay seller profile info', error);
      EbaySellerProfileInfoState({
        loading: false,
      });
    }
  };

  return { syncSellerProfileInfo };
};

export default useSyncEbaySellerProfileInfo;
