/* eslint-disable prettier/prettier */
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { type AmazonProductType } from '../TYPES';
import {
  AIBestSellerProductCategoriesState,
  type categoryObjectType,
} from '../../stateManagement/AIBestSellerProductCategoriesState';
import { log } from 'console';
import { orderBy } from 'lodash';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { useReactiveVar } from '@apollo/client';

const db = getFirestore();
export interface AmazonAIBestSellerDiscovererType extends AmazonProductType {
    category?: string;
    dateCreated?: string;
    mainCategory?: string;
    reason?: string;
    region?: string;
    score?: number;
  }


const useAmazonAIBestSellerDiscoverer = () => {
  const [loading, setLoading] = React.useState(false);
  const userMarketplace = useReactiveVar(ActiveMarketPlace);
  console.log('userMarketplace', userMarketplace);
  
  const getCategories = async () => {
    setLoading(true);
    // Call the function that fetches the products
    const aiCategoryRef = collection(db, `ai_products/${userMarketplace}/categories`);
    const aiCategories = await getDocs(aiCategoryRef);

    // const products: Record<string, object[]> = {};
    // aiCategories.docs.forEach((doc) => {
    //   console.log('doc', doc.data());
    //   if (!products[doc.id]) {
    //     products[doc.id] = [];
    //   }
    //   products[doc.id].push(doc.data());
    // });

    const products = aiCategories.docs.map((doc) => doc.data()) ?? [];

    console.log('products', products);
    

    AIBestSellerProductCategoriesState(products as categoryObjectType[]);
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, [userMarketplace]);

  

  const [products, setProducts] = React.useState<
    AmazonAIBestSellerDiscovererType[]
  >([]);

  const separatedPrices = (price:string) => {
    if (!price) return null;
    const match = price.match(/^([^\d]+)([\d.]+)$/);
    if (match) {
        return {
            currency: match?.[1] ?? '', // Extracts the currency symbol
            amount: parseFloat(match?.[2] || '') // Extracts the numeric value
        };
    }
    return null; // In case no match is found
}

  const getCategory = async (category: string) => {
    setLoading(true);
    // Call the function that fetches the products
    const productRef = collection(
      db,
      `ai_products/${userMarketplace}/categories/${category}/products`,
    );
    const allProducts = await getDocs(productRef);

    const _products = allProducts.docs.map((doc) => {
        console.log('doc.data()', doc.data());
        const {currency, amount} = separatedPrices(doc.data()?.product_price as string) ?? {currency: '', amount: 0};
        return ({...doc.data(), product_price: amount, currency})
    }) ?? [];

    setProducts(_products as AmazonAIBestSellerDiscovererType[]);
    setLoading(false);
    return _products;
  };

  return { loading, getCategory, products };
};

export default useAmazonAIBestSellerDiscoverer;
