/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export interface IEbayInventoryLocation {
  location: {
    address: {
      city: string,
      stateOrProvince: string,
      country: 'US' | 'GB' | 'DE' | 'FR' | 'AU' | 'CA' | 'IT' | 'ES',
      postalCode: string,
      addressLine1?: string,
    },
    locationId?: string,
  };
  name?: string;
  merchantLocationStatus: 'ENABLED' | 'DISABLED';
  merchantLocationKey?: string;
  locationTypes: ['WAREHOUSE' | 'STORE' | 'DROP_OFF'];
}

export const EbayInventoryLocationsState = makeVar<IEbayInventoryLocation[]>(
  [],
);
