import { User, UserInfo, getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { UserStateManagerState } from '../../stateManagement/userStateManager';
import OneSignal from 'react-onesignal';

const database = getFirestore();
const auth = getAuth();
const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<undefined | boolean>(undefined);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        const userRef = doc(database, 'users', user?.uid);
        onSnapshot(userRef, (_doc) => {
          console.log('Current data: ', _doc.data());
          UserStateManagerState({
            ...UserStateManagerState(),
            user: _doc.data(),
            loading: false,
            isEmailVerified: !!user?.emailVerified,
          });
        });

        (async () => {
          await OneSignal.login(user?.uid);
          OneSignal.User.addEmail(user?.email as string);
        })();
      }
    });
  }, []);

  return isLoggedIn;
};

export default useIsLoggedIn;
