/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { getAuth } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import React from 'react';
import {
  type AmazonProductType,
  type IEbayDraftListing,
  type IEbayDraftListingVariations,
  type IEbayListingGroup,
} from '../TYPES';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import {
  ModalStateManager,
  useModalStateManager,
} from '../../stateManagement/ModalStateManager';
import { EbayListingsState } from '../../stateManagement/EbayListingsState';
import { isSandbox } from '../../env';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import useEbaySettings from './useEbaySettings';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { ActiveEbayPlaceId } from '../../stateManagement/ActiveEbayPlaceId';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { useTokenValidation } from '../../stateManagement/useTokenValidation';
import { AuthTokenState } from '../../stateManagement/AuthTokenState';

const db = getFirestore();
const auth = getAuth();
const functions = getFunctions();
const useEbayDraftListing = () => {
  const [loading, setLoading] = React.useState(false);
  const [variations, setVariations] = React.useState<
    // eslint-disable-next-line prettier/prettier
    IEbayDraftListingVariations[] | []
  >([]);

  const [ebayListing, setEbayListing] =
    React.useState<IEbayListingGroup | null>(null);

  const [listingStatus, setListingStatus] = React.useState<string | null>(null);
  const [listedMarketPlace, setListedMarketPlace] = React.useState<
    // eslint-disable-next-line prettier/prettier
    string | null
  >(null);

  const { AE_TOKEN, EBAY_AUTH_TOKEN } = useReactiveVar(AuthTokenState);

  const marketPlaceId = useReactiveVar(ActiveEbayPlaceId);

  const Navigate = useNavigate();

  const { hasAllSettings } = useEbaySettings();

  const [, updateModal, resetModal] = useModalStateManager();

  const [ebayOriginalProduct, setEbayOriginalProduct] =
    React.useState<AmazonProductType | null>(null);

  const getEbayDraftListing = async (sku: string) => {
    try {
      setLoading(true);
      const itemRef = doc(
        db,
        `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings/${sku}`,
      );
      onSnapshot(itemRef, (_doc) => {
        setEbayListing(_doc.data() as IEbayListingGroup);

        setListedMarketPlace(_doc.data()?.marketPlace as string);
      });
      const variationsRef = collection(
        db,
        `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings/${sku}/variations`,
      );
      onSnapshot(variationsRef, (_doc) => {
        const data = _doc.docs.map((item) => ({
          id: item?.id,
          ...(item.data() as IEbayDraftListing),
        }));
        setVariations(data as IEbayDraftListingVariations[]);
      });
      setLoading(false);
    } catch (error) {
      console.error('Error getting ebay draft listing', error);
      setLoading(false);
    }
  };

  const saveEbayDraftListing = async (data: IEbayListingGroup, sku: string) => {
    try {
      setLoading(true);
      const itemRef = doc(
        db,
        `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings/${sku}`,
      );
      await updateDoc(itemRef, { ...data });
      setLoading(false);
    } catch (error) {
      console.error('Error saving ebay draft listing', error);
      updateModal({
        title: 'Error',
        subtitle: 'Something went wrong, Please try again later',
        type: 'WARNING',
      });
      setLoading(false);
    }
  };

  const ebayAIdescriptionEditor = async (description: string) => {
    try {
      ModalStateManager({
        title: `✨ Hold Tight! Drop Compare AI is working its magic! ✨`,
        subtitle:
          'Your product description is being rewritten with cutting-edge SEO techniques to make it stand out, drive more clicks, and skyrocket your sales!',
        type: 'OPERATION',
      });
      const createDraft = httpsCallableFromURL(
        functions,
        firebaseCall('ebayAIdescriptionEditor'),
      );
      const response = await createDraft({
        description,
      });

      console.log('response', response?.data);

      ModalStateManager({
        type: null,
      });
      return response?.data;
    } catch (error) {
      console.error('Error saving ebay draft listing', error);
      ModalStateManager({
        type: null,
      });
    }
  };

  const ebayAItitleEditor = async (title: string) => {
    try {
      ModalStateManager({
        title: `✨ Hold Tight! Drop Compare AI is working its magic! ✨`,
        subtitle:
          'Your product title is being rewritten with cutting-edge SEO techniques to make it stand out, drive more clicks, and skyrocket your sales!',
        type: 'OPERATION',
      });
      const createDraft = httpsCallableFromURL(
        functions,
        firebaseCall('ebayAItitleEditor'),
      );
      const response = await createDraft({
        title,
      });

      console.log('response', response?.data);

      ModalStateManager({
        type: null,
      });
      return response?.data;
    } catch (error) {
      console.error('Error saving ebay draft listing', error);
      ModalStateManager({
        type: null,
      });
    }
  };

  const syncEbayListings = async () => {
    try {
      setLoading(true);
      const itemRef = collection(
        db,
        `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings`,
      );
      const items = await getDocs(
        query(itemRef, where('isSandbox', '==', isSandbox)),
      );
      const data = items.docs.map((_doc) => ({
        id: _doc?.id,
        ...(_doc.data() as IEbayListingGroup),
      }));
      EbayListingsState(data);
      setLoading(false);
    } catch (error) {
      console.error('Error getting ebay draft listing', error);
      setLoading(false);
    }
  };

  const createDraftFromAmazonTaskManager = async (productId: string) => {
    try {
      if (!hasAllSettings) {
        Navigate('/setup-ebay-account');
        return;
      }
      ModalStateManager({
        title: `✨ Hold Tight! Drop Compare AI is working its magic! ✨`,
        subtitle:
          'Your product is being analyzed to create a draft listing for eBay!',
        type: 'OPERATION',
      });
      const createDraft = httpsCallableFromURL(
        functions,
        firebaseCall('createDraftFromAmazonTaskManager'),
      );
      const response = await createDraft({
        productId,
        region: ActiveMarketPlace(),
      });

      console.log('response', response?.data);

      ModalStateManager({
        type: null,
      });
      return response?.data;
    } catch (error) {
      console.error('Error saving ebay draft listing', error);
      ModalStateManager({
        type: null,
      });
    }
  };

  const createDraftFromAETaskManager = async (productId: string) => {
    try {
      if (!hasAllSettings) {
        Navigate('/setup-ebay-account');
        return;
      }
      if (AE_TOKEN) {
        ModalStateManager({
          title: `Ali Express Account is NOT Connected!`,
          subtitle:
            'Please connect you Ali Express account to create a draft listing!',
          type: 'WARNING',
        });

        return;
      }
      ModalStateManager({
        title: `✨ Hold Tight! Creating Draft from Ali Express product! ✨`,
        subtitle:
          'Your product is being analyzed to create a draft listing for eBay!',
        type: 'OPERATION',
      });
      const createDraft = httpsCallableFromURL(
        functions,
        firebaseCall('createDraftFromAETaskManager'),
      );
      const response = await createDraft({
        productId,
        region: ActiveMarketPlace(),
      });

      console.log('response', response?.data);

      ModalStateManager({
        type: null,
      });
      return response?.data;
    } catch (error) {
      console.error('Error saving ebay draft listing', error);
      resetModal();
    }
  };

  const deleteEbayListing = async (id: string) => {
    const itemRef = doc(
      db,
      `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings/${id}`,
    );
    await deleteDoc(itemRef);
    resetModal();
    Navigate('/ebay-listings');
  };

  return {
    ebayListing,
    getEbayDraftListing,
    saveEbayDraftListing,
    ebayOriginalProduct,
    loading,
    ebayAIdescriptionEditor,
    ebayAItitleEditor,
    syncEbayListings,
    createDraftFromAmazonTaskManager,
    createDraftFromAETaskManager,
    marketPlaceId,
    listingStatus,
    ebayDraftListingVariations: variations,
    listedMarketPlace,
    deleteEbayListing,
  };
};

export default useEbayDraftListing;
