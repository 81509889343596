import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import EbayLogo from '../../components/EbayLogo';
import { useReactiveVar } from '@apollo/client';
import { EbaySellerProfileInfoState } from '../../stateManagement/EbaySellerProfileInfo';
import useStyles from '../../utils/hooks/useStyles';
import Loading from '../../components/Loading';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import { AuthTokenState } from '../../stateManagement/AuthTokenState';
import { ebayAuthUrl } from '../../components/modals/TokenSateComponent';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import {
  ModalStateManager,
  useModalStateManager,
} from '../../stateManagement/ModalStateManager';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import useSyncEbaySellerProfileInfo from '../../utils/hooks/useSyncEbaySellerProfileInfo';
import { useTokenValidation } from '../../stateManagement/useTokenValidation';
import { isSandbox } from '../../env';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

const db = getFirestore();
const auth = getAuth();

const functions = getFunctions();

const UserProfileLanding = () => {
  const { data, loading } = useReactiveVar(EbaySellerProfileInfoState);
  console.log('EbaySellerProfileInfoState', data);

  const { syncSellerProfileInfo } = useSyncEbaySellerProfileInfo();
  const validate = useTokenValidation();
  useEffect(() => {
    syncSellerProfileInfo();
    validate();
  }, []);
  const theme = useTheme();

  const Styles = useStyles();
  const style = Styles(theme);

  const [, updateModal, resetModal] = useModalStateManager();

  const { AE_TOKEN, EBAY_AUTH_TOKEN } = useReactiveVar(AuthTokenState);

  const disconnectEbayAccount = async () => {
    const ref = doc(
      db,
      `organisations/${SelectedOrganisationAccountState()?.id}/tokens/${isSandbox ? 'EBAY_AUTH_TOKEN_SANDBOX' : 'EBAY_AUTH_TOKEN'}`,
    );
    await deleteDoc(ref);
    resetModal();
    syncSellerProfileInfo();
    validate();
  };

  const setUserPreferencesWithExternalData = async () => {
    const set = httpsCallableFromURL(
      functions,
      firebaseCall('setUserPreferencesWithExternalData'),
    );
    const response = await set();
    console.log('response', response);
  };

  const getNotificationPreferences = async () => {
    const get = httpsCallableFromURL(
      functions,
      firebaseCall('getNotificationPreferences'),
    );
    const response = await get();
    console.log('response', response);
  };

  const disconnectModal = async () => {
    updateModal({
      type: 'WARNING',
      title: 'Disconnect eBay Account',
      subtitle: 'Are you sure you want to disconnect your eBay account?',
      onAction: () => {
        disconnectEbayAccount();
      },
      onActionText: 'Disconnect',
      onActionColor: 'error',
    });
  };

  if (loading)
    return (
      <Box sx={{ ...CENTER, width: '100%', height: '100%' }}>
        <Loading />
      </Box>
    );

  return (
    <Container>
      <Box style={style.itemContainer}>
        <Button
          onClick={() => {
            setUserPreferencesWithExternalData();
          }}
          variant="contained"
        >
          setUserPreferencesWithExternalData
        </Button>
        getNotificationPreferences
        <Button
          onClick={() => {
            getNotificationPreferences();
          }}
          variant="contained"
        >
          getNotificationPreferences
        </Button>
        <EbayLogo size={100} />
        {EBAY_AUTH_TOKEN ? (
          <Box>
            <Box>
              <Box
                sx={{
                  p: 2,
                  textAlign: 'center',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                }}
              >
                <Typography variant="h5" fontWeight="bold">
                  Connect Your eBay Account
                </Typography>
              </Box>

              {/* Content Section */}
              <Box sx={{ p: 3 }}>
                <Typography
                  fontWeight={700}
                  fontSize={18}
                  variant="body1"
                  color="textPrimary"
                  paragraph
                >
                  Unlock the full potential of your eBay business by connecting
                  your account. Here`s what you’ll gain:
                </Typography>

                {/* Benefits List */}
                <List>
                  {[
                    'Seamless Inventory Management: Easily list, edit, and track your products directly from our platform.',
                    'Faster Listings: Create product listings in seconds with streamlined tools tailored to save you time.',
                    'Centralized Control: Manage all your eBay listings in one place, no matter how many products you have.',
                    'Real-Time Updates: Stay in sync with your eBay store—inventory, orders, and prices update automatically.',
                    'Performance Insights: Gain actionable insights into your sales to help you grow and succeed.',
                    'No Hassles: Simplify your workflow with easy-to-use features built for eBay sellers like you.',
                  ].map((benefit, index) => (
                    <ListItem key={index} sx={{ alignItems: 'flex-start' }}>
                      <ListItemIcon>
                        <GridCheckCircleIcon sx={{ color: '#0074d9' }} />
                      </ListItemIcon>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box sx={{ ...CENTER }}>
                <Button
                  onClick={() => {
                    window.open(ebayAuthUrl as string, '_blank');
                  }}
                  variant="contained"
                  color="success"
                >
                  Connect
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">Store Name: {data?.userId}</Typography>
            <Typography variant="body1">Email: {data?.email}</Typography>
            <Typography variant="body1">
              Feedback Score: {data?.feedbackScore}
            </Typography>
            <Typography variant="body1">
              eBay Good Standing: {data?.eBayGoodStanding}
            </Typography>
            <Typography variant="body1">
              Registration Date: {data?.registrationDate}
            </Typography>
            <Typography variant="body1">Site: {data?.site}</Typography>
            <Typography variant="body1">
              Store Owner: {data?.storeOwner}
            </Typography>
            <Typography variant="body1">
              Seller Business Type: {data?.sellerBusinessType}
            </Typography>
            <Box mt={3}>
              <Button
                onClick={() => {
                  disconnectModal();
                }}
                variant="contained"
                color="error"
              >
                Disconnect
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default UserProfileLanding;
