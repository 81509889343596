/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ModalStateManager } from '../../../stateManagement/ModalStateManager';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../../utils/hooks/useEbayInventoryItem';
import { useReactiveVar } from '@apollo/client';
import { EbayPolicyState } from '../../../stateManagement/EbayPolicyState';
import { EbayInventoryLocationsState } from '../../../stateManagement/EbayInventoryLocationsState';
import { type IEbaySettings } from '../../../utils/TYPES';
import useEbaySettings from '../../../utils/hooks/useEbaySettings';
import { cloneDeep } from 'lodash';

const EbayMerchantLocation = () => {
  const { palette } = useTheme();
  const {
    listInventoryItemOnEbay,
    createInventoryLocation,
    createReturnPolicy,
    getReturnPolicy,
    getFulfillmentPolicy,
    getPaymentPolicy,
    createFulfillmentPolicy,
    createPaymentPolicy,
    getInventoryLocations,
  } = useEbayInventoryItem();

  useEffect(() => {
    // update inventory Location in database
    getInventoryLocations();
  }, []);

  const marketPlaceId = 'EBAY_US';

  const { ebaySettings, saveEbaySettings } = useEbaySettings();

  useEffect(() => {
    setFormState(cloneDeep(ebaySettings));
  }, [ebaySettings]);

  const ebayInventoryLocations = useReactiveVar(EbayInventoryLocationsState);
  const [formState, setFormState] = React.useState<
    // eslint-disable-next-line prettier/prettier
    IEbaySettings | null | undefined
  >({
    location: {
      merchantLocationKey: '',
    },
  });
  console.log('formState', formState);

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;
    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: IEbaySettings | null | undefined) => {
      const updatedState = { ...prevState };
      let current: Record<string, any> = updatedState;

      for (let i = 0; i < path.length - 1; i++) {
        const key = path[i];

        // Check if the key exists, if not, create an empty object
        if (!current[key]) {
          current[key] = {};
        }

        current = current[key];
      }

      // Set the final value
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value;

      return updatedState; // Return the updated state
    });
  };

  return (
    <Box>
      <Typography
        variant="h6"
        gutterBottom
        mt={4}
        sx={{ textDecoration: 'underline', mb: 2 }}
      >
        Merchant Location
      </Typography>

      <Grid item xs={12} mt={2}>
        <FormLabel required>Merchant Location</FormLabel>
        <Grid container sx={{ alignItems: 'center' }} spacing={2}>
          <Grid item xs={10}>
            <Select
              value={formState?.location?.merchantLocationKey}
              onChange={(e) => {
                handleChange(e, ['location', 'merchantLocationKey']);
              }}
              fullWidth
              sx={{
                backgroundColor: palette?.background?.default,
              }}
              required
            >
              {!!ebayInventoryLocations?.length &&
                ebayInventoryLocations.map((location) => (
                  <MenuItem
                    key={location?.merchantLocationKey}
                    value={location?.merchantLocationKey}
                  >
                    {location?.merchantLocationKey} (
                    {location?.location?.address?.city}
                    {' - '}
                    {location?.location?.address?.country}
                    {' - '}
                    {location?.location?.address?.postalCode})
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => {
                ModalStateManager({
                  type: 'INVENTORY_LOCATION',
                });
              }}
              startIcon={<Add />}
            >
              Add Location
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Button
        onClick={() => {
          saveEbaySettings(formState as IEbaySettings);
        }}
        variant="contained"
      >
        Save
      </Button>
    </Box>
  );
};

export default EbayMerchantLocation;
