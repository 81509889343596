/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';
import { type AmazonProductType } from '../TYPES';
import {
  addDoc,
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  CompareListState,
  type CompareListStateType,
} from '../../stateManagement/CompareListState';
import { useReactiveVar } from '@apollo/client';
import moment from 'moment';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

const auth = getAuth();
const db = getFirestore();

const useCompareList = ({
  setIsStartCompareListModalOpen,
}: {
  setIsStartCompareListModalOpen?: (t: boolean) => void,
}) => {
  const { activeCompareListId, compareLists } =
    useReactiveVar(CompareListState);

  const [loading, setLoading] = React.useState(false);

  // if there is no active list on we keep products in queue and add it after list creation
  const [productsInQueue, setProductsInQueue] = React.useState<
    // eslint-disable-next-line prettier/prettier
    AmazonProductType[]
  >([]);

  const _selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );

  const createComparList = async (name: string) => {
    if (!auth?.currentUser || !name) return;
    setLoading(true);
    console.log('creating compare list');
    try {
      const compareListRef = collection(
        db,
        `organisations/${_selectedOrganisationAccountState?.id}/compareLists`,
      );

      // Add a new document with a generated id.
      const createdDoc = await addDoc(compareListRef, {
        name,
        dateCreated: new Date().toISOString(),
        products: [],
        active: true,
      });
      setLoading(false);
      return createdDoc?.id;
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const syncAllCompareLists = () => {
    // get all compare lists
    if (!auth?.currentUser) return;
    const compareListRef = collection(
      db,
      `organisations/${_selectedOrganisationAccountState?.id}/compareLists`,
    );

    console.log('syncing compare lists', auth?.currentUser.uid);

    const queryRef = query(compareListRef, where('active', '==', true));

    onSnapshot(queryRef, (snapshot) => {
      const data = snapshot.docs
        .map((doc: any) => {
          if (doc.exists()) {
            return {
              id: doc.id,
              products: doc.data()?.products as AmazonProductType[],
              dateCreated: doc.data()?.dateCreated
                ? moment(doc.data()?.dateCreated as string).format(
                    'YY-MMM-DD HH:mm',
                  )
                : '',
              name: doc.data()?.name as string,
              active: !!doc.data()?.active,
            };
          }
          return null;
        })
        .filter(Boolean) as CompareListStateType['compareLists'];
      CompareListState({
        ...CompareListState(),
        compareLists: data,
      });
    });
  };

  const setCompareListAsActive = (id: string | null) => {
    if (!auth?.currentUser) return;
    CompareListState({
      ...CompareListState(),
      activeCompareListId: id,
    });

    // set a compare list as active
  };

  useEffect(() => {
    if (!activeCompareListId) return;
    CompareListState({
      ...CompareListState(),
      activeCompareList: compareLists?.find(
        (list) => list.id === activeCompareListId,
      ) as CompareListStateType['activeCompareList'],
    });
    if (productsInQueue?.length > 0) {
      addRemoveProductsToCompareList(productsInQueue);
      setProductsInQueue([]);
    }
  }, [activeCompareListId, compareLists]);

  const addRemoveProductsToCompareList = (products: AmazonProductType[]) => {
    if (!auth?.currentUser) return;
    if (!activeCompareListId && setIsStartCompareListModalOpen) {
      setIsStartCompareListModalOpen(true);
      setProductsInQueue(products);
      return;
    }

    const compareListRef = doc(
      db,
      `organisations/${_selectedOrganisationAccountState?.id}/compareLists/${activeCompareListId}`,
    );

    const currentProducts =
      CompareListState()?.activeCompareList?.products ?? [];

    if (products.length === 1) {
      // Single product logic: toggle add/remove
      const product = products[0];
      const exists = currentProducts.some((p) => p?.asin === product?.asin);
      const updatedProducts = exists
        ? currentProducts.filter((p) => p?.asin !== product?.asin) // Remove if exists
        : [...currentProducts, product]; // Add if doesn't exist

      setDoc(
        compareListRef,
        {
          products: updatedProducts,
        },
        { merge: true },
      );
      return;
    }

    // Multiple products logic: Add without duplicates
    const productAsins = new Set(currentProducts.map((p) => p?.asin));
    const productsToAdd = products.filter(
      (product) => !productAsins.has(product?.asin),
    );

    const updatedProducts = [...currentProducts, ...productsToAdd];

    setDoc(
      compareListRef,
      {
        products: updatedProducts,
      },
      { merge: true },
    );
  };

  const updateCompareListState = async ({
    compareId,
    active,
  }: {
    compareId: string,
    active: boolean,
  }) => {
    if (!auth?.currentUser) return;
    const compareListRef = doc(
      db,
      `organisations/${_selectedOrganisationAccountState?.id}/compareLists/${activeCompareListId}`,
    );

    await setDoc(
      compareListRef,
      {
        compareId,
        active,
      },
      { merge: true },
    );
  };

  return {
    createComparList,
    syncAllCompareLists,
    setCompareListAsActive,
    addRemoveProductsToCompareList,
    updateCompareListState,
    loading,
  };
};

export default useCompareList;
