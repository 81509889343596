/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export interface categoryObjectType {
  name: string;
  description: string;
}

export const AIBestSellerProductCategoriesState = makeVar<
  categoryObjectType[] | []
>([]);
