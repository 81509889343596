import { collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useMemo } from 'react';
import { useUser } from '../../stateManagement/userStateManager';
import {
  OrganisationAccounts,
  type OrganisationAccountType,
} from '../../stateManagement/OrganisationAccounts';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { useReactiveVar } from '@apollo/client';

const db = getFirestore();
const useSyncOrganisationAccounts = () => {
  const { user } = useUser();

  const selectedOrganisationAccountState = useReactiveVar(
    SelectedOrganisationAccountState,
  );

  useEffect(() => {
    if (selectedOrganisationAccountState?.id) {
      const orgRef = doc(
        db,
        `organisations/${selectedOrganisationAccountState?.id}`,
      );

      const unsubscribe = onSnapshot(orgRef, (_doc) => {
        if (_doc.exists()) {
          SelectedOrganisationAccountState({
            ...SelectedOrganisationAccountState(),
            ...(_doc?.data() as OrganisationAccountType),
          });
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [selectedOrganisationAccountState?.id]);

  useEffect(() => {
    if (!user?.uid) {
      OrganisationAccounts([]);
      return;
    }

    const ref = collection(db, `users/${user?.uid}/linkedOrganisation`);
    onSnapshot(ref, (snapshot) => {
      const accounts = snapshot.docs.map((_doc) => ({
        id: _doc?.id,
        ..._doc.data(),
      }));
      console.log('accounts', user?.uid);

      OrganisationAccounts(accounts as OrganisationAccountType[]);
      if (accounts?.length > 0) {
        SelectedOrganisationAccountState(
          accounts[0] as OrganisationAccountType,
        );
      } else {
        SelectedOrganisationAccountState(null);
      }
    });
  }, [user?.uid]);
};

export default useSyncOrganisationAccounts;
