import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../utils/hooks/useAuth';
import { useUser } from '../stateManagement/userStateManager';
import Logo from '../components/Logo';

const VerifyEmail = () => {
  const navigate = useNavigate();

  const { logout } = useAuth();
  const { user } = useUser();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        px: 3, // Padding for mobile responsiveness
      }}
    >
      {/* Logo */}
      <Logo size={200} />

      {/* Title */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, mt: 4 }}>
        Verify Your Email
      </Typography>

      {/* Message */}
      <Typography variant="body1" sx={{ mb: 4 }}>
        We have sent a verification email to your email address:{' '}
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          {user?.email}
        </Typography>
        . Please check your inbox and spam/junk folder.
      </Typography>

      {/* Logout Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          logout();
        }}
        sx={{ mt: 2 }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default VerifyEmail;
