import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  useTheme,
} from '@mui/material';
import { Info, Palette } from '@mui/icons-material';
import { ModalStateManager } from '../stateManagement/ModalStateManager';

interface PlanComparison {
  feature: string;
  starter: string | number;
  pro: string | number;
}

const data: PlanComparison[] = [
  { feature: 'Credits', starter: '15,000', pro: '25,000' },
  { feature: 'Product Listing Limit', starter: '200', pro: '800' },
  { feature: 'Market Comparison', starter: '3 Markets', pro: '3 Markets' },
  { feature: 'Auto-Import to eBay', starter: 'Yes', pro: 'Yes' },
  { feature: 'Niche Finder Access', starter: 'Yes', pro: 'Yes' },
  { feature: 'Team Members', starter: 'No', pro: 'Yes' },
  { feature: 'Monthly Price', starter: '$19', pro: '$35' },
  {
    feature: 'Yearly Price',
    starter: '$180\n($15/month)',
    pro: '$360\n($30/month)',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: '1px solid #e0e0e0', // Adds a border between columns
  '&:last-child': {
    borderRight: 'none', // Removes border for the last column
  },
  borderBottom: '1px solid #e0e0e0', // Adds a bottom border for rows
}));

const ComparisonTable: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Pricing
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography variant="h6">Feature</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h6">Starter Plan</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h6">Pro Plan</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    row.feature === 'Yearly Price' ||
                    row.feature === 'Monthly Price'
                      ? palette?.surface?.main
                      : palette?.surface?.light,
                }}
              >
                <StyledTableCell>
                  <Typography
                    fontWeight={
                      row.feature === 'Yearly Price' ||
                      row.feature === 'Monthly Price'
                        ? 'bold'
                        : 'normal'
                    }
                    onClick={() => {
                      if (row.feature === 'Credits') {
                        ModalStateManager({
                          type: 'CREDIT_SYSTEM',
                        });
                      }
                    }}
                    sx={{
                      cursor: row.feature === 'Credits' ? 'pointer' : 'auto',
                    }}
                  >
                    {row.feature} {row.feature === 'Credits' && <Info />}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    fontWeight={
                      row.feature === 'Yearly Price' ||
                      row.feature === 'Monthly Price'
                        ? 'bold'
                        : 'normal'
                    }
                  >
                    {row.starter}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    fontWeight={
                      row.feature === 'Yearly Price' ||
                      row.feature === 'Monthly Price'
                        ? 'bold'
                        : 'normal'
                    }
                  >
                    {row.pro}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ComparisonTable;
