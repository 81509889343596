/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import {
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import {
  Box,
  FormLabel,
  Grid2,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type IEbayInventoryLocation } from '../../stateManagement/EbayInventoryLocationsState';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../utils/hooks/useEbayInventoryItem';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { type MarketPlaceCountryType } from '../../utils/TYPES';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const EbayLocationInventoryModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();
  const initState: IEbayInventoryLocation = {
    location: {
      address: {
        city: '',
        stateOrProvince: '',
        country: ActiveMarketPlace() as MarketPlaceCountryType,
        postalCode: '',
        addressLine1: '',
      },
    },
    name: '',
    merchantLocationStatus: 'ENABLED',
    locationTypes: ['WAREHOUSE'],
  };

  const [formState, setFormState] = React.useState(initState);
  const [merchantLocationKey, setMerchantLocationKey] = React.useState('');

  const { createInventoryLocation } = useEbayInventoryItem();

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;

    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: any) => {
      const updatedState = { ...prevState };

      let current: any = updatedState;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value; // Always update the value correctly

      return updatedState; // Return the updated state
    });
  };

  // is all filled all required fields should be filled
  const isReady = useMemo(() => {
    const {
      name,
      merchantLocationStatus,
      locationTypes,
      location: {
        address: { country, city, postalCode, stateOrProvince, addressLine1 },
      },
    } = formState;

    return (
      name &&
      merchantLocationStatus &&
      locationTypes &&
      country &&
      city &&
      postalCode &&
      addressLine1 &&
      stateOrProvince
    );
  }, [formState]);

  const {
    name,
    merchantLocationStatus,
    locationTypes,
    location: {
      address: { country, city, postalCode, stateOrProvince, addressLine1 },
    },
  } = formState;

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <EbayLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          Add Inventory Location
        </Typography>
        <Box sx={{ ...CENTER, flexDirection: 'column' }}>
          <Grid2 container maxWidth={'50%'} spacing={2}>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>Merchant Location Key</FormLabel>
              <TextField
                required
                variant="outlined"
                value={merchantLocationKey}
                onChange={(e) => {
                  setMerchantLocationKey(e?.target?.value);
                }}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>Name</FormLabel>
              <TextField
                variant="outlined"
                value={name}
                onChange={(e) => {
                  handleChange(e, ['name']);
                }}
                fullWidth
              />
            </Grid2>
            {/* <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Status</FormLabel>
              <Select
                value={merchantLocationStatus}
                onChange={(e) => {
                  handleChange(e, ['merchantLocationStatus']);
                }}
                fullWidth
              >
                <MenuItem value="ENABLED">Enabled</MenuItem>
                <MenuItem value="DISABLED">Disabled</MenuItem>
              </Select>
            </Grid2> */}
            {/* <Grid2 size={{ xs: 6 }}>
              <FormLabel required>Location Types</FormLabel>
              <Select
                value={locationTypes}
                onChange={(e) => {
                  handleChange(e, ['locationTypes']);
                }}
                fullWidth
              >
                <MenuItem value="WAREHOUSE">Warehouse</MenuItem>
                <MenuItem value="STORE">Store</MenuItem>
                <MenuItem value="DROP_OFF">Drop Off</MenuItem>
              </Select>
            </Grid2> */}
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>Country</FormLabel>
              <Select
                value={country}
                onChange={(e) => {
                  handleChange(e, ['location', 'address', 'country']);
                }}
                fullWidth
              >
                <MenuItem value="US">United States</MenuItem>
                <MenuItem value="GB">United Kingdom</MenuItem>
                <MenuItem value="DE">Germany</MenuItem>
                <MenuItem value="FR">France</MenuItem>
                <MenuItem value="AU">Australia</MenuItem>
                <MenuItem value="CA">Canada</MenuItem>
                <MenuItem value="IT">Italy</MenuItem>
                <MenuItem value="ES">Spain</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>City</FormLabel>
              <TextField
                variant="outlined"
                value={city}
                onChange={(e) => {
                  handleChange(e, ['location', 'address', 'city']);
                }}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>State Or Province</FormLabel>
              <TextField
                variant="outlined"
                value={stateOrProvince}
                onChange={(e) => {
                  handleChange(e, ['location', 'address', 'stateOrProvince']);
                }}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel required>Postal Code</FormLabel>
              <TextField
                variant="outlined"
                value={postalCode}
                onChange={(e) => {
                  handleChange(e, ['location', 'address', 'postalCode']);
                }}
                fullWidth
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <FormLabel>Address Line 1</FormLabel>
              <TextField
                variant="outlined"
                value={addressLine1}
                onChange={(e) => {
                  handleChange(e, ['location', 'address', 'addressLine1']);
                }}
                fullWidth
              />
            </Grid2>
          </Grid2>
          <LoadingButton
            loading={modalState?.loading}
            disabled={!isReady}
            startIcon={<Add />}
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => {
              createInventoryLocation(formState, merchantLocationKey);
            }}
          >
            Add Location
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default EbayLocationInventoryModal;
