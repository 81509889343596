import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../utils/CONSTS/ENDPOINTS';

const functions = getFunctions();
const useOrganisationAccounts = () => {
  const createNewOrganisationAccount = async (name: string) => {
    const _createNewOrganisationAccount = httpsCallableFromURL(
      functions,
      firebaseCall('createNewOrganisationAccount'),
    );
    await _createNewOrganisationAccount({
      name,
    });
  };

  const joinExistingOrganisationAccount = async (inviteId: string) => {
    const _joinExistingOrganisationAccount = httpsCallableFromURL(
      functions,
      firebaseCall('joinExistingOrganisationAccount'),
    );
    await _joinExistingOrganisationAccount({
      inviteId,
    });
  };

  return { createNewOrganisationAccount, joinExistingOrganisationAccount };
};

export default useOrganisationAccounts;
