/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { useUser } from './userStateManager';
import { ActiveEbayPlaceId } from './ActiveEbayPlaceId';
import getEbayMarketPlaceId from '../utils/helpers/getEbayMarketPlaceId';

export const userMarketplaceOptions = [
  { code: 'GB', text: 'GB', flag: '🇬🇧' },
  { code: 'US', text: 'US', flag: '🇺🇸' },
];

export type ActiveMarketPlaceType = 'US' | 'GB';

export const ActiveMarketPlace = makeVar<ActiveMarketPlaceType | undefined>(
  undefined,
);

export const useSyncMarketPlace = () => {
  const marketPlace = useReactiveVar(ActiveMarketPlace);
  const { user, updateUser } = useUser();

  // useEffect(() => {
  //   if (user?.marketPlace) {
  //     // ActiveMarketPlace(user.marketPlace as ActiveMarketPlaceType);
  //     console.log('user.marketPlace', user.marketPlace);

  //   }
  // }, [user]);

  useEffect(() => {
    if (
      !!user?.displayName &&
      marketPlace &&
      user?.marketPlace !== marketPlace
    ) {
      console.log('marketPlace', marketPlace, user?.marketPlace);

      const _marketPlace = marketPlace ?? 'US';
      updateUser({ marketPlace: _marketPlace });
      ActiveEbayPlaceId(getEbayMarketPlaceId(_marketPlace));
    }
  }, [marketPlace]);
};
