import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#0b1126',
        color: '#fff',
        padding: '2rem 1rem',
        mt: '2rem',
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      >
        {/* Address Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            DropCompare
          </Typography>
          <Typography variant="body2">
            Lytchett House, 13 Freeland Park, Wareham Road, Poole,
            <br />
            Dorset, BH166FA
            <br />
            United Kingdom
          </Typography>
        </Grid>

        {/* Links Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Box>
            <Link
              href="/privacy-policy"
              underline="hover"
              color="inherit"
              sx={{ display: 'block', mb: 1 }}
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms-and-conditions"
              underline="hover"
              color="inherit"
              sx={{ display: 'block' }}
            >
              Terms & Conditions
            </Link>
          </Box>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2">
            Email:{' '}
            <Link
              href="mailto:info@dropcompare.com"
              color="inherit"
              underline="hover"
            >
              info@dropcompare.com
            </Link>
          </Typography>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box
        sx={{
          textAlign: 'center',
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          marginTop: '2rem',
          paddingTop: '1rem',
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} DropCompare. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
