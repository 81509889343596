import React from 'react';
import { Box, Typography, Paper, Grid, Divider } from '@mui/material';

const CreditSystem = () => {
  return (
    <Box sx={{ p: 4, textAlign: 'center', maxWidth: 800, margin: 'auto' }}>
      {/* Title */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
        How the Credit System Works
      </Typography>

      {/* Intro */}
      <Typography variant="body1" sx={{ mb: 4 }}>
        DropCompare uses credits to access various features. Depending on your
        plan, you’ll receive a set number of credits each month. Here’s how you
        can use them:
      </Typography>

      {/* Features Section */}
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Credit Costs by Feature
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>Amazon Search:</b> 30 credits per request
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>Compare Products:</b> 10 credits per product
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>AI Category Finder:</b> 5 credits per search
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>AI Consulting:</b> 10 credits per message
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>Daily Product Tracking:</b> 44 credits per product/day
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>Profit Calculator:</b> 5 credits per use
            </Typography>
          </Grid> */}
        </Grid>
      </Paper>

      {/* Plans Section */}
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Plans and Credits
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>Basic Plan:</b> 15,000 credits per month
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <b>Pro Plan:</b> 25,000 credits per month
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">
          Use your credits for any feature, and they reset every month!
        </Typography>
      </Paper>

      {/* Example Section */}
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Example Usage
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          With the <b>Basic Plan</b> (15,000 credits), you can:
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          - Compare 1,000 products (10 credits each)
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          - Perform 500 Amazon Searches (30 credits each)
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          - Mix and match features as needed!
        </Typography>
      </Paper>
    </Box>
  );
};

export default CreditSystem;
