/* eslint-disable @typescript-eslint/no-explicit-any */
import { Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { a11yProps } from '../../utils/helpers/a11yProps';
import TabPanel from '../../components/TabPanel';
import useEbaySettings from '../../utils/hooks/useEbaySettings';
import { type ISettings, type IEbaySettings } from '../../utils/TYPES';
import { cloneDeep } from 'lodash';
import EbaySetting from './components/EbaySetting';

const SettingsLanding = () => {
  const { palette } = useTheme();
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <Box
        mt={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">
          <Settings sx={{ fontSize: 50 }} /> Settings
        </Typography>
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        aria-label="full width tabs example"
      >
        <Tab
          label="Ebay"
          sx={{ color: palette?.primary?.main }}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ color: palette?.primary?.main }}
          label="Settings"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ color: palette?.primary?.main }}
          label="Policies"
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={String(tabValue)} index={0}>
        <EbaySetting />
      </TabPanel>
    </Container>
  );
};

export default SettingsLanding;
