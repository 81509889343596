/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import {
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import {
  Box,
  FormLabel,
  Grid2,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type IEbayInventoryLocation } from '../../stateManagement/EbayInventoryLocationsState';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import useEbayInventoryItem from '../../utils/hooks/useEbayInventoryItem';
import { type IEbayPolicy } from '../../stateManagement/EbayPolicyState';
import { ActiveEbayPlaceId } from '../../stateManagement/ActiveEbayPlaceId';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const EbayPaymentPolicyModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();
  const initState: IEbayPolicy['paymentPolicies'][0] = {
    /* PaymentPolicyRequest */
    categoryTypes: [
      {
        /* CategoryType */ default: false,
        name: 'ALL_EXCLUDING_MOTORS_VEHICLES',
      },
    ],
    immediatePay: true,
    marketplaceId: ActiveEbayPlaceId() as string,
    name: '',
  };

  const [formState, setFormState] = React.useState(initState);

  const { createPaymentPolicy } = useEbayInventoryItem();

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;

    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: any) => {
      const updatedState = { ...prevState };

      let current: any = updatedState;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value; // Always update the value correctly

      return updatedState; // Return the updated state
    });
  };

  console.log('formState', formState);

  // is all filled all required fields should be filled
  const isReady = useMemo(() => {
    return (
      formState?.name?.length > 0 &&
      formState?.marketplaceId?.length > 0 &&
      formState?.immediatePay !== undefined
    );
  }, [formState]);

  const { name, marketplaceId, immediatePay } = formState;

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <EbayLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          Create Return Policy
        </Typography>
        <Box sx={{ ...CENTER, flexDirection: 'column' }}>
          <Grid2 container maxWidth={'70%'} spacing={2}>
            <Grid2 size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={(e) => {
                  handleChange(e, ['name']);
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Select
                fullWidth
                label="Marketplace"
                value={marketplaceId}
                onChange={(e) => {
                  handleChange(e, ['marketplaceId']);
                }}
              >
                <MenuItem value={'EBAY_US'}>EBAY US (United State)</MenuItem>
                <MenuItem value={'EBAY_GB'}>EBAY UK (United Kingdom)</MenuItem>
              </Select>
            </Grid2>
          </Grid2>
          <LoadingButton
            loading={modalState?.loading}
            disabled={!isReady}
            startIcon={<Add />}
            sx={{ mt: 3 }}
            variant="contained"
            onClick={() => {
              createPaymentPolicy(formState);
            }}
          >
            Create Return Policy
          </LoadingButton>
          {modalState?.error && (
            <Typography mt={2} color={'error'}>
              {modalState?.error}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EbayPaymentPolicyModal;
