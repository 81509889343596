import * as React from 'react';
import AuthComponent from '../../components/AuthCompoenet';
import Box from '../../components/Box';
import useAuth from '../../utils/hooks/useAuth';
import { Grid2 } from '@mui/material';
import COLORS from '../../utils/CONSTS/COLORS';
import BenefitsList from '../../components/BenefitList';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';

const RegisterPage = () => {
  const { register } = useAuth();
  return (
    <Grid2 container sx={{ width: '100vw', height: '100vh' }}>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <AuthComponent
          type="Register"
          onClick={(email, password, displayName) => {
            console.log('email', email);
            console.log('password', password);
            register({ email, password, displayName });
          }}
        />
      </Grid2>
      <Grid2
        size={{ xs: 0, md: 6 }}
        sx={{
          backgroundColor: COLORS.PRIMARY_DARK,
          ...CENTER,
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <BenefitsList />
      </Grid2>
    </Grid2>
  );
};

export default RegisterPage;
