import React from 'react';

import {
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import Loading from '../Loading';
import EbayLogo from '../EbayLogo';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const OperationLoadingModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          height: { xs: '95%', sm: '70%', lg: '60%' },
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          {modalState?.title}
        </Typography>
        {modalState?.subtitle && (
          <Typography
            variant="subtitle1"
            mb={5}
            textAlign="center"
            sx={{ maxWidth: '80%', opacity: 0.7 }}
          >
            {modalState?.subtitle}
          </Typography>
        )}
        <Loading size={200} />
        {modalState?.data?.logo === 'ebay' && <EbayLogo size={100} />}
      </Box>
    </Modal>
  );
};

export default OperationLoadingModal;
