import { type ContentInterface } from '../utils/hooks/useLocalContent';

export const COMPARE_LIST_CONTENT: ContentInterface = {
  en: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
  es: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
  pt: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
  de: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
  fr: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
  it: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
  pl: {
    CONTENT: {
      TT_LIST_SETTING: 'Mange your list, create or select another list',
      CREATE_NEW_LIST: 'Create a new Compare Price list!',
      SELECT_FROM_EXISTING: 'Select from existing active lists',
      TT_SELECT_FROM_EXISTING:
        'these are all lists you have created but not yet executed',
    },
  },
};
