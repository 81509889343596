import React from 'react';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  Link,
  CssBaseline,
} from '@mui/material';

// Define types for the sections and their points
interface Section {
  title: string;
  points: string[];
}

const privacyPolicy: Section[] = [
  {
    title: 'Introduction',
    points: [
      'DropCompare values your privacy and is committed to protecting your personal data.',
      'This Privacy Policy explains how we collect, use, and share your information when you use our platform.',
    ],
  },
  {
    title: 'Information We Collect',
    points: [
      'Account Information: Name, email address, and payment details when you sign up for an account.',
      'Listings Data: Product descriptions, prices, images, and other details related to your use of our product comparison tools.',
      'Device Information: IP address, browser type, operating system, and device type collected automatically.',
      'Usage Data: Pages viewed, actions taken on the platform, and time spent on the Service.',
      'Cookies: We use cookies and similar technologies to enhance your experience and gather analytics.',
    ],
  },
  {
    title: 'How We Use Your Information',
    points: [
      'To provide, maintain, and improve the Service.',
      'To process payments and manage subscriptions.',
      'To communicate with you about updates, promotions, and support.',
      'To ensure compliance with third-party platform policies.',
      'To detect and prevent fraud or unauthorized activities.',
    ],
  },
  {
    title: 'Sharing Your Information',
    points: [
      'We do not sell your personal information.',
      'We may share your data with service providers who help us operate the platform, such as payment processors and analytics providers.',
      'We may disclose information when required by law or to protect our rights and comply with legal obligations.',
      'We may share data with third-party platforms like eBay or Amazon only with your consent or as necessary to provide the Service.',
    ],
  },
  {
    title: 'Your Rights and Choices',
    points: [
      'Access and Correction: You may access, update, or correct your personal information by logging into your account or contacting us directly.',
      'Data Deletion: You can request that we delete your personal data, subject to legal or contractual obligations.',
      "Marketing Preferences: You can opt out of receiving promotional emails by clicking the 'unsubscribe' link in our communications.",
      'Cookie Preferences: Adjust your cookie settings through your browser to limit data collection.',
    ],
  },
  {
    title: 'Data Security',
    points: [
      'We implement industry-standard security measures to protect your personal information.',
      'No method of transmission or storage is 100% secure; therefore, we cannot guarantee absolute security.',
    ],
  },
  {
    title: 'Third-Party Links',
    points: [
      'The Service may contain links to third-party websites or services.',
      'We are not responsible for the privacy practices of those third parties.',
      'Please review their privacy policies before sharing any data.',
    ],
  },
  {
    title: "Children's Privacy",
    points: [
      'The Service is not intended for children under 13.',
      'We do not knowingly collect data from children under 13.',
      'If we become aware of such data, we will delete it promptly.',
    ],
  },
  {
    title: 'Changes to This Privacy Policy',
    points: [
      'We may update this Privacy Policy from time to time.',
      "Any changes will be posted with a revised 'Effective Date'.",
      'Your continued use of the Service after changes are posted constitutes acceptance of the updated policy.',
    ],
  },
  {
    title: 'Contact Information',
    points: [
      'If you have questions or concerns about this Privacy Policy, please contact us:',
      'Email: info@dropcompare.com',
    ],
  },
];

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: '#f9f9f9',
          color: '#333',
          borderRadius: 2,
          padding: 4,
          mt: 4,
          boxShadow: 1,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            textAlign="center"
            color="primary"
          >
            Privacy Policy
          </Typography>
          {privacyPolicy.map((section, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                {section.title}
              </Typography>
              <List>
                {section.points.map((point, pointIndex) => (
                  <ListItem key={pointIndex} sx={{ pl: 2 }}>
                    <Typography variant="body1">{point}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
          <Typography
            variant="body2"
            textAlign="center"
            color="text.secondary"
            sx={{ mt: 4 }}
          >
            Last Updated: January 18, 2025
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
